<template>
    <div :class="{
        '': !add_wrapper,
        'wtb-wrapper': add_wrapper
    }">
        <div
            class="form_section text-left"
            :data-form="data_form"
            ref="pageTop"
        >
            <loading
                :active.sync="isLoading"
                :can-cancel="false"
                :is-full-page="fullPage"
            ></loading>
            <button
                v-if="show_close"
                id="closeModal"
                @click="$modal.hide('quote_modal')"
            >
                x
            </button>
            <div class="step_form two new_step-form" v-if="step === 'lead-types'">
                <div class="step_form_top step-title">
                    <h2 class="">How can we help you?</h2>
                </div>
                <div class="step_form_main">
                    <div class="group_windows_list">
                        <div
                            v-for="(value, key) in this.lead_types"
                            :key="key"
                            :data-key="key"
                            @click="selectTypes(value.id, 1, 0)"
                            :class="{
                                'group_windows radio checked': value.id == quote_request.leadType,
                                'group_windows radio': value.id != quote_request.leadType,
                            }"
                        >
                            <label
                                ><span>{{ value.name }}</span></label
                            >
                        </div>
                    </div>
                    <div class="button_cont">
                        <button type="button" @click.prevent="next()">
                            Next
                            <i class="next_arrow">⟶</i>
                        </button>
                    </div>
                </div>
            </div>

            <div v-if="quote_request.leadType == 'H'">
                <div class="step_form two new_step-form" v-if="step === 'service-types'">
                    <div class="step_form_top step-title">
                        <h2>What type of project is this?</h2>
                    </div>
                    <div class="step_form_main">
                        <div class="group_windows_list">
                            <div
                                v-for="(value, key) in this.service_types"
                                :key="key"
                                :data-key="key"
                                @click="selectTypes(value.id, 2, 0)"
                                :class="{
                                    'group_windows radio checked':
                                        value.id == quote_request.serviceType,
                                    'group_windows radio': value.id != quote_request.serviceType,
                                }"
                            >
                                <label
                                    ><span>{{ value.name }}</span></label
                                >
                            </div>
                            <div class="form-error mt-2" v-show="empty_value">
                                Please select an option
                            </div>
                        </div>
                        <div class="button_cont">
                            <button class="no-x-margin" type="button" @click.prevent="prev()">
                                <i class="prev_arrow">⟵</i>
                                Previous
                            </button>
                            <button
                                type="button"
                                class="custom_btn next_btn"
                                @click.prevent="next(quote_request.serviceType)"
                            >
                                Next
                                <i class="next_arrow">⟶</i>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="step_form three new_step-form" v-if="step === 'home-types'">
                    <div class="step_form_top step-title">
                        <h2>What type of home do you have?</h2>
                    </div>
                    <div class="step_form_main step_three">
                        <div class="group_windows_list">
                            <div
                                v-for="(value, key) in this.home_types"
                                :key="key"
                                :data-key="key"
                                @click="selectTypes(value.id, 3, 0)"
                                :class="{
                                    'group_windows radio checked':
                                        value.id == quote_request.homeType,
                                    'group_windows radio': value.id != quote_request.homeType,
                                }"
                            >
                                <label
                                    ><span>{{ value.name }}</span></label
                                >
                            </div>
                            <div class="form-error mt-2" v-show="empty_value">
                                Please select an option
                            </div>
                        </div>
                        <div class="button_cont">
                            <button class="no-x-margin" type="button" @click.prevent="prev()">
                                <i class="prev_arrow">⟵</i>
                                Previous
                            </button>
                            <button
                                type="button"
                                class="custom_btn next_btn"
                                @click.prevent="next(quote_request.homeType)"
                            >
                                Next
                                <i class="next_arrow">⟶</i>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="step_form three new_step-form" v-if="step === 'interested-products'">
                    <div class="step_form_top step-title">
                        <h2>Which products are you interested in buying?</h2>
                        <h3>Check all that apply</h3>
                    </div>
                    <div class="step_form_main step_three">
                        <div class="group_windows_list">
                            <div
                                v-for="(value, key) in this.interested_products"
                                :key="key"
                                :data-key="key"
                                @click="selectTypes(value.id, 4, 0)"
                                :class="{
                                    'group_windows group_windows_multi radio checked':
                                        quote_request.interestedProducts.indexOf(
                                            value.id.toString()
                                        ) != -1,
                                    'group_windows group_windows_multi radio':
                                        quote_request.interestedProducts.indexOf(
                                            value.id.toString()
                                        ) == -1,
                                }"
                            >
                                <label
                                    ><span>{{ value.name }}</span></label
                                >
                            </div>
                            <div v-if="this.interested_products.length < 3"
                                :class="{
                                    'group_windows group_windows_multi radio other_field checked':
                                        hasOInterestedProducts == 1,
                                    'group_windows group_windows_multi radio other_field':
                                        hasOInterestedProducts == 0,
                                }"
                                @click="setOthers(4)"
                            >
                                <label class="steps-label"><span>Other</span></label>
                                <input
                                    type="text"
                                    class="steps-textbox"
                                    value=""
                                    v-model="quote_request.oInterestedProducts"
                                    v-on:keyup="selectOthers(4)"
                                />
                            </div>
                            <div class="form-error mt-2" v-show="empty_value">
                                Please select an option
                            </div>
                        </div>

                        <div class="button_cont">
                            <button class="no-x-margin" type="button" @click.prevent="prev()">
                                <i class="prev_arrow">⟵</i>
                                Previous
                            </button>
                            <button
                                type="button"
                                class="custom_btn next_btn"
                                @click.prevent="next(quote_request.interestedProducts)"
                            >
                                Next
                                <i class="next_arrow">⟶</i>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="step_form three new_step-form" v-if="step === 'window-amount'">
                    <div class="step_form_top step-title">
                        <h2>How many windows do you need for this project?</h2>
                    </div>
                    <div class="step_form_main step_three">
                        <div class="group_windows_list">
                            <div
                                v-for="(value, key) in this.window_amount"
                                :key="key"
                                :data-key="key"
                                @click="selectTypes(value.id, 5, 0)"
                                :class="{
                                    'group_windows radio checked':
                                        value.id == quote_request.windowAmount,
                                    'group_windows radio': value.id != quote_request.windowAmount,
                                }"
                            >
                                <label
                                    ><span>{{ value.name }}</span></label
                                >
                            </div>

                            <div
                                :class="{
                                    'group_windows radio other_field checked':
                                        hasOWindowAmount == 1,
                                    'group_windows radio other_field': hasOWindowAmount == 0,
                                }"
                                @click="setOthers(5)"
                            >
                                <label class="steps-label"><span>Other</span></label>
                                <input
                                    type="text"
                                    class="steps-textbox"
                                    value=""
                                    v-model="quote_request.oWindowAmount"
                                />
                            </div>
                            <div class="form-error mt-2" v-show="empty_value">
                                Please select an option
                            </div>
                        </div>
                        <div class="button_cont">
                            <button class="no-x-margin" type="button" @click.prevent="prev()">
                                <i class="prev_arrow">⟵</i>
                                Previous
                            </button>
                            <button
                                type="button"
                                class="custom_btn next_btn"
                                @click.prevent="next(quote_request.windowAmount)"
                            >
                                Next
                                <i class="next_arrow">⟶</i>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- ~./Step three -->

                <!-- Step four starts here -->
                <div class="step_form three new_step-form" v-if="step === 'door-amount'">
                    <div class="step_form_top step-title">
                        <h2>How many doors do you need for this project?</h2>
                    </div>
                    <div class="step_form_main step_three">
                        <div class="group_windows_list">
                            <div
                                v-for="(value, key) in this.door_amount"
                                :key="key"
                                :data-key="key"
                                @click="selectTypes(value.id, 6, 0)"
                                :class="{
                                    'group_windows radio checked':
                                        value.id == quote_request.doorAmount,
                                    'group_windows radio': value.id != quote_request.doorAmount,
                                }"
                            >
                                <label
                                    ><span>{{ value.name }}</span></label
                                >
                            </div>
                            <div class="form-error mt-2" v-show="empty_value">
                                Please select an option
                            </div>
                        </div>
                        <div class="button_cont">
                            <button class="no-x-margin" type="button" @click.prevent="prev()">
                                <i class="prev_arrow">⟵</i>
                                Previous
                            </button>
                            <button
                                type="button"
                                class="custom_btn next_btn"
                                @click.prevent="next(quote_request.doorAmount)"
                            >
                                Next
                                <i class="next_arrow">⟶</i>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="step_form three new_step-form" v-if="step === 'frame-preference'">
                    <div class="step_form_top step-title">
                        <h2>Do you have a preference for frames?</h2>
                    </div>
                    <div class="step_form_main step_three">
                        <div class="group_windows_list">
                            <div
                                @click="selectFramePreference('aluminum')"
                                :class="{
                                    'group_windows group_windows_multi radio checked':
                                        quote_request.framePreference.aluminum == '1',
                                    'group_windows group_windows_multi radio':
                                        quote_request.framePreference.aluminum != '1'
                                }"
                            >
                                <label><span>Aluminum</span></label>
                            </div>
                            <div
                                @click="selectFramePreference('vinyl')"
                                :class="{
                                    'group_windows group_windows_multi radio checked':
                                        quote_request.framePreference.vinyl == '1',
                                    'group_windows group_windows_multi radio':
                                        quote_request.framePreference.vinyl != '1'
                                }"
                            >
                                <label><span>Vinyl</span></label>
                            </div>
                            <div
                                @click="selectFramePreference('not_sure')"
                                :class="{
                                    'group_windows group_windows_multi radio checked':
                                        quote_request.framePreference.not_sure == '1',
                                    'group_windows group_windows_multi radio':
                                        quote_request.framePreference.not_sure != '1'
                                }"
                            >
                                <label><span>Not sure</span></label>
                            </div>
                        <div class="form-error mt-2" v-show="empty_value">
							Please select an option
						</div>
                        </div>
                        <div class="button_cont">
                            <button class="no-x-margin" type="button" @click.prevent="prev()">
                                <i class="prev_arrow">⟵</i>
                                Previous
                            </button>
                            <button
                                type="button"
                                class="custom_btn next_btn"
                            @click.prevent="next(quote_request.framePreference)"
                            >
                                Next
                                <i class="next_arrow">⟶</i>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="step_form three new_step-form" v-if="step === 'interested-installation'">
                    <div class="step_form_top step-title">
                        <h2>Do you need installation services for this project?</h2>
                    </div>
                    <div class="step_form_main step_three">
                        <div class="group_windows_list">
                            <div
                                :class="{
                                    'group_windows radio checked':
                                        this.quote_request.interested_installation == 'yes',
                                    'group_windows radio':
                                        this.quote_request.interested_installation != 'yes',
                                }"
                                @click="selectTypes('yes', 8, 1)"
                            >
                                <label
                                    ><span
                                        >Yes. I want to buy products and have them installed by the
                                        same provider.</span
                                    ></label
                                >
                            </div>
                            <div
                                :class="{
                                    'group_windows radio checked':
                                        this.quote_request.interested_installation == 'no',
                                    'group_windows radio':
                                        this.quote_request.interested_installation != 'no',
                                }"
                                @click="selectTypes('no', 8, 2)"
                            >
                                <label><span>No. I have an installer already.</span></label>
                            </div>
                            <div
                                :class="{
                                    'group_windows radio checked':
                                        this.quote_request.interested_installation == 'helpme',
                                    'group_windows radio':
                                        this.quote_request.interested_installation != 'helpme',
                                }"
                                @click="selectTypes('helpme', 8, 1)"
                            >
                                <label><span>Not sure. Please help me decide.</span></label>
                            </div>
                        <div class="form-error mt-2" v-show="empty_value">
							Please select an option
						</div>
                        </div>
                        <div class="button_cont">
                            <button class="no-x-margin" type="button" @click.prevent="prev()">
                                <i class="prev_arrow">⟵</i>
                                Previous
                            </button>
                            <button
                                type="button"
                                class="custom_btn next_btn"
                                @click.prevent="next(quote_request.interested_installation)"
                            >
                                Next
                                <i class="next_arrow">⟶</i>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- ~./Step three -->

                <!-- Step four starts here -->
                <div class="step_form three new_step-form" v-if="step === 'window-importance'">
                    <div class="step_form_top step-title">
                        <h2>Which product benefits interest you most?</h2>
                        <h3>Select all that apply</h3>
                    </div>
                    <div class="step_form_main step_three">
                        <div class="group_windows_list">
                            <div
                                v-for="(value, key) in this.window_importance"
                                :key="key"
                                :data-key="key"
                                @click="selectTypes(value.id, 9, 0)"
                                :class="{
                                    'group_windows group_windows_multi radio checked':
                                        quote_request.windowImportance.indexOf(value.id.toString()) !=
                                        -1,
                                    'group_windows group_windows_multi radio':
                                        quote_request.windowImportance.indexOf(value.id.toString()) ==
                                        -1,
                                }"
                            >
                                <label
                                    ><span>{{ value.name }}</span></label
                                >
                            </div>

                            <div
                                :class="{
                                    'group_windows group_windows_multi radio other_field checked':
                                        hasOWindowImportance == 1,
                                    'group_windows group_windows_multi radio other_field':
                                        hasOWindowImportance == 0,
                                }"
                                @click="setOthers(7)"
                            >
                                <label class="steps-label"><span>Other</span></label>
                                <input
                                    type="text"
                                    class="steps-textbox"
                                    value=""
                                    v-model="quote_request.oWindowImportance"
                                    v-on:keyup="selectOthers(9)"
                                />
                            </div>
                        <div class="form-error mt-2" v-show="empty_value">
							Please select an option
						</div>
                        </div>
                        <div class="button_cont">
                            <button class="no-x-margin" type="button" @click.prevent="prev()">
                                <i class="prev_arrow">⟵</i>
                                Previous
                            </button>
                            <button
                                type="button"
                                class="custom_btn next_btn"
							@click.prevent="next(quote_request.windowImportance)"
                            >
                                Next
                                <i class="next_arrow">⟶</i>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- ~./Step three -->

                <div class="step_form two new_step-form" v-if="step === 'new-buy-plans'">
                    <div class="step_form_top step-title">
                        <h2>When are you planning to buy new windows and/or doors?</h2>
                    </div>
                    <div class="step_form_main step_two">
                        <div class="group_windows_list">
                            <div
                                v-for="(value, key) in this.new_buy_plans"
                                :key="key"
                                :data-key="key"
                                @click="selectTypes(value.id, 10, 0)"
                                :class="{
                                    'group_windows radio checked':
                                        value.id == quote_request.newBuyPlan,
                                    'group_windows radio': value.id != quote_request.newBuyPlan,
                                }"
                            >
                                <label
                                    ><span>{{ value.name }}</span></label
                                >
                            </div>
                        <div class="form-error mt-2" v-show="empty_value">
							Please select an option
						</div>
                        </div>
                        <div class="button_cont">
                            <button class="no-x-margin" type="button" @click.prevent="prev()">
                                <i class="prev_arrow">⟵</i>
                                Previous
                            </button>
                            <button
                                type="button"
                                class="custom_btn next_btn"
							@click.prevent="next(quote_request.newBuyPlan)"
                            >
                                Next
                                <i class="next_arrow">⟶</i>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="step_form two new_step-form" v-if="step === 'interested-financing'">
                    <div class="step_form_top step-title">
                        <h2>Special financing</h2>
                    </div>
                    <div class="step_form_main">
                        <div class="step-subtitle">
                            Are you interested in special financing that allows you to pay for
                            this project over time?
                        </div>
                        <div class="group_windows_list">
                            <div
							class="group_windows radio"
							:class="{'checked':quote_request.interested_financing === 1}"
                                @click="selectSpecialFinancing(1)"
                            >
                                <label><span>Yes</span></label>
                            </div>
                            <div
							class="group_windows radio"
							:class="{'checked':quote_request.interested_financing === 0}"
                                @click="selectSpecialFinancing(0)"
                            >
                                <label><span>No</span></label>
                            </div>
                        <div class="form-error mt-2" v-show="empty_value">
							Please select an option
						</div>
                        </div>
                        <div class="button_cont">
                            <button class="no-x-margin" type="button" @click.prevent="prev()">
                                <i class="prev_arrow">⟵</i>
                                Previous
                            </button>
                            <button
                                type="button"
                                class="custom_btn next_btn"
							@click.prevent="next(quote_request.interested_financing)"
                            >
                                Next
                                <i class="next_arrow">⟶</i>
                            </button>
                        </div>
                    </div>
                </div>

              <!-- Residential Leads Project location -->
              <div class="step_form two new_step-form" v-if="step === 'project-location'">
                <div class="step_form_top step-title">
                  <h2>Project location</h2>
                </div>
                <div class="step_form_main">
                  <div class="step-subtitle">
                    What is the location of your project?
                  </div>
                  <div class="group_windows_list">
                    <!-- usa by default -->
                    <div
                      class="group_windows radio"
                      :class="{'checked': quote_request.projectDetails.unitedStatesSelected === 1 }"
                      @click="selectProjectLocationCountry(1)"
                    >
                      <label><span>United States</span></label>
                    </div>
                    <div
                      class="group_windows radio"
                      :class="{
                          'checked': quote_request.projectDetails.unitedStatesSelected === 0
                        }"
                      @click="selectProjectLocationCountry(0)"
                    >
                      <label><span>Other</span></label>
                    </div>
                  </div>

                  <div v-if="showOtherCountries">
                    <div class="field-container field-country ml-2 mb-2" style="width: 75%;">
                      <label class="if-not-mobile contact-label placeholder"
                      ><span>Country</span></label
                      >
                      <select v-model="quote_request.projectDetails.country" @change="onProjectLocationCountryChange" class="steps-select">
                        <option
                          v-for="item in this.countriesOutsideUS"
                          :key="item"
                          :value="item"
                        >
                          {{ item }}
                        </option>
                      </select>
                    </div>
                    <div class="form-error mt-1 ml-2" v-if="quote_request.projectDetails.country === ''">
                      Please select a country
                    </div>
                  </div>

                  <div v-if="showProjectLocationZip" class="field-container field-zip mt-2">
                    <label class="if-not-mobile contact-label placeholder">
                      <span class="ml-2">Zip Code</span>
                    </label>
                    <input
                      id="project-location-zip"
                      type="text"
                      v-model="quote_request.projectDetails.zip"
                      placeholder="Zip Code"
                      class="steps-textbox input-zip mb-0"
                      @input="validateProjectLocationZipCode"
                    />
                    <label v-show="false" class="if-mobile contact-label placeholder">
                      <span>Zip Code</span>
                    </label>
                    <div v-if="projectLocationZipIsInvalid" style="margin-top: 1px;" class="ml-2" :class="{ 'form-error': projectLocationZipIsInvalid != '' }">
                      Invalid Zip Code Format
                    </div>
                    <div v-if="empty_value" style="margin-top: 1px;" class="form-error ml-2">
                      Zip code is required
                    </div>
                  </div>

                  <div class="button_cont mt-5">
                    <button type="button" @click.prevent="prev()">
                      <i class="prev_arrow">⟵</i>
                      Previous
                    </button>
                    <button
                      type="button"
                      class="custom_btn next_btn"
                      @click.prevent="next(quote_request.projectLocation)"
                    >
                      <i class="next_arrow">⟶</i>
                      Next
                    </button>
                  </div>
                </div>
              </div>

                <!-- Step four starts here -->
                <div
                    class="step_form step_form_field four new_step-form"
                    v-if="step === 'personal-info-form'"
                >
                    <div class="step_form_top step-title">
                        <h2>Tell our dealer how to reach you.</h2>
                    </div>
                    <div class="step_form_main personal-info-form personal-info-form-grid">
                        <div class="step-field-group">
                            <label class="contact-label"><span>First Name</span></label>
                            <div class="field-container">
                                <input
                                    type="text"
                                    value=""
                                    v-model="quote_request.firstName"
                                    placeholder="First Name"
                                    class="steps-textbox"
                                />
                                <div
                                    :class="{
                                        'form-error': errors.firstName != '',
                                    }"
                                >
                                    {{ this.errors.firstName }}
                                </div>
                            </div>
                        </div>

                        <div class="step-field-group">
                            <label class="contact-label"><span>Last Name</span></label>
                            <div class="field-container">
                                <input
                                    type="text"
                                    value=""
                                    v-model="quote_request.lastName"
                                    placeholder="Last Name"
                                    class="steps-textbox"
                                />
                                <div :class="{ 'form-error': errors.lastName != '' }">
                                    {{ this.errors.lastName }}
                                </div>
                            </div>
                        </div>
                        <div class="step-field-group">
                            <label class="contact-label"><span>Email</span></label>
                            <div class="field-container">
                                <input
                                    type="email"
                                    v-model="quote_request.email"
                                    value=""
                                    placeholder="Email"
                                    class="steps-textbox"
                                />
                                <div :class="{ 'form-error': errors.email != '' }">
                                    {{ this.errors.email }}
                                </div>
                            </div>
                        </div>

                        <div class="step-field-group">
                            <label class="contact-label"><span>Phone Number</span></label>
                            <div class="field-container">
                                <input
                                    type="tel"
                                    v-model="quote_request.phone"
                                    value=""
                                    placeholder="Phone Number"
                                    @input="sanitizePhoneNumber"
                                    class="steps-textbox"
                                />
                                <div :class="{ 'form-error': errors.phone != '' }">
                                    {{ this.errors.phone }}
                                </div>
                            </div>
                        </div>

					<div class="step-field-group">
                            <label class="contact-label"><span>Address</span></label>
                            <div class="field-container">
                                <input
                                    type="text"
                                    v-model="quote_request.address1"
                                    value=""
                                    placeholder="Address"
                                    class="steps-textbox input-address"
                                    @change="shouldConfirmAddress = true; addressConfirmed = false;"
                                />
                                <div :class="{ 'form-error': errors.address1 != '' }">
                                    {{ this.errors.address1 }}
                                </div>
                            </div>
                        </div>

                        <div class="step-field-group">
                            <label class="contact-label"><span>City</span></label>
                            <div class="field-container">
                                <input
                                    type="text"
                                    v-model="quote_request.city"
                                    value=""
                                    placeholder="City"
                                    class="steps-textbox"
                                />
                                <div :class="{ 'form-error': errors.city != '' }">
                                    {{ this.errors.city }}
                                </div>
                            </div>
                        </div>

                        <div class="step-field-group">
                            <label class="contact-label"><span>State</span></label>
                            <div
                                class="field-container"
                                v-if="quote_request.country == this.default_country"
                            >
                                <select
                                    v-model="quote_request.state"
                                    placeholder="State"
                                    class="steps-select"
                                >
                                    <option value="" disabled selected>Select State</option>
                                    <option
                                        v-for="(item, key) in this.states"
                                        :key="key"
                                        :value="item.name"
                                    >
                                        {{ item.name }}
                                    </option>
                                </select>

                                <div :class="{ 'form-error': errors.state != '' }">
                                    {{ this.errors.state }}
                                </div>
                            </div>
                            <div class="field-container" v-else>
                                <input
                                    type="text"
                                    v-model="quote_request.state"
                                    value=""
                                    placeholder="State"
                                    class="steps-textbox"
                                />

                                <div :class="{ 'form-error': errors.state != '' }">
                                    {{ this.errors.state }}
                                </div>
                            </div>
                        </div>

                        <div class="step-field-group">
                            <label class="contact-label"><span>Postal Code</span></label>
                            <div class="field-container">
                                <input
                                    type="text"
                                    v-model="quote_request.zip"
                                    value=""
                                    placeholder="Postal Code"
                                    class="steps-textbox"
                                    @input="sanitizeZipCode"
                                />

                                <div :class="{ 'form-error': errors.zip != '' }">
                                    {{ this.errors.zip }}
                                </div>
                            </div>
                        </div>

                        <div class="step-field-group">
                            <label class="contact-label"><span>Country</span></label>
                            <div class="field-container">
                                <select v-model="quote_request.country" class="steps-select">
                                    <option
                                        v-for="item in this.countries"
                                        :key="item"
                                        :value="item"
                                    >
                                        {{ item }}
                                    </option>
                                </select>

                                <div :class="{ 'form-error': errors.country != '' }">
                                    {{ this.errors.country }}
                                </div>
                            </div>
                        </div>

                       <!-- <div class="step-field-group mt-4">
                            <div class="field-container">
                                <div
                                    class="step-field-group group_windows_list group_windows_list_small"
                                >
                                    <label class="text-label"
                                    ><span>Preferred contact method</span></label
                                    >
                                </div>
                                <div class="step-field-group group_windows_list group_windows_list_small field-group-left field-group-always-left mt-2">
                                    <div
                                        :class="{
                                        'group_windows group_windows_multi radio checked':
                                            'E' == quote_request.contactMethod,
                                        'group_windows group_windows_multi radio': 'E' != quote_request.contactMethod,
                                    }"
                                        @click="selectTypes('E', 12, 1)"
                                    >
                                        <label>
                                            <span>Email</span>
                                        </label>
                                    </div>
                                </div>
                                <div class="step-field-group group_windows_list group_windows_list_small field-group-right field-group-always-right mt-2">
                                    <div
                                        :class="{
                                        'group_windows group_windows_multi radio checked':
                                            'P' == quote_request.contactMethod,
                                        'group_windows group_windows_multi radio': 'P' != quote_request.contactMethod,
                                    }"
                                        @click="selectTypes('P', 12, 1)"
                                    >
                                        <label>
                                            <span>Phone</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div :class="{ 'form-error': errors.contactMethod != '' }">
                                {{ this.errors.contactMethod }}
                            </div>
                        </div> -->

                        <div class="step-field-group">
                            <div class="group_windows_list">
                                <div
                                    :class="{
                                        'group_windows group_windows_multi radio checked':
                                            'Y' == quote_request.newsletter,
                                        'group_windows group_windows_multi radio':
                                            'Y' != quote_request.newsletter,
                                    }"
                                    @click="selectTypes('Y', 12, 2)"
                                >
                                    <label>
                                        <span>
                                            Please send me email updates about new products, news, and
                                            promotions.
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <input type="hidden" id="window_amount" v-model="windowAmount" />
                        <input type="hidden" id="door_amount" v-model="doorAmount" />
                        <input type="hidden" id="cluid" v-model="cluid" />

                        <div class="button_cont pt-4">
                            <button class="no-x-margin" type="button" @click.prevent="prev()">
                                <i class="prev_arrow">⟵</i>
                                Previous
                            </button>
                            <button
                                :data-form="data_form"
                                type="button"
                                class="custom_btn next_btn final_button"
                                @click.prevent="postLead()"
                            >
                                <i class="next_arrow">⟶</i>
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Business Professional -->
            <div v-if="quote_request.leadType == 'B'">
                <div class="step_form two new_step-form" v-if="step === 'profession'">
                    <div class="step_form_top step-title">
                        <h2>What best describes your profession?</h2>
                    </div>
                    <div class="step_form_main">
                        <div class="group_windows_list">
                            <div
                                v-for="(value, key) in this.b_description"
                                :key="key"
                                :data-key="key"
                                @click="selectTypes(value.id, 2, 0)"
                                :class="{
                                    'group_windows radio checked':
                                        value.id == quote_request.bDescription,
                                    'group_windows radio': value.id != quote_request.bDescription,
                                }"
                            >
                                <label
                                    ><span>{{ value.name }}</span></label
                                >
                            </div>
                          <div class="form-error mt-2" v-show="empty_value">
                            Please select an option
                          </div>
                        </div>

                        <!-- What is your company name? -->
                        <div v-if="quote_request.bDescription">
                          <div>
                            <label class="d-block text-white" style="margin-left: 10px;" for="companyName">What is your company name?</label>
                            <input
                              id="companyName"
                              type="text"
                              class="steps-textbox other-field"
                              v-model="quote_request.bName"
                            />
                          </div>
                          <div class="form-error mt-2" style="margin-left: 10px;" v-show="false">
                            Error text
                          </div>
                        </div>
                        <!-- End What is your company name? -->

                        <div class="button_cont">
                            <button class="no-x-margin" type="button" @click.prevent="prev()">
                                <i class="prev_arrow">⟵</i>
                                Previous
                            </button>
                            <button
                                type="button"
                                class="custom_btn next_btn"
							@click.prevent="next(quote_request.bDescription)"
                            >
                                Next
                                <i class="next_arrow">⟶</i>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- ~./Step one -->

                <!-- Step two starts here -->

                <!-- ~./Step two -->

                <!-- Step three starts here -->
                <div class="step_form three new_step-form" v-if="step === 'b-project'">
                    <div class="step_form_top step-title">
                        <h2>Is this a new project or are you an existing customer?</h2>
                    </div>
                    <div class="step_form_main step_three">
                        <div class="group_windows_list">
                            <div
                                v-for="(value, key) in this.b_project"
                                :key="key"
                                :data-key="key"
                                @click="selectTypes(value.id, 3, 0)"
                                :class="{
                                    'group_windows radio checked':
                                        value.id == quote_request.bProject,
                                    'group_windows radio': value.id != quote_request.bProject,
                                }"
                            >
                                <label
                                    ><span>{{ value.name }}</span></label
                                >
                            </div>
                        <div class="form-error mt-2" v-show="empty_value">
							Please select an option
						</div>
                        </div>
                        <div class="button_cont">
                            <button class="no-x-margin" type="button" @click.prevent="prev()">
                                <i class="prev_arrow">⟵</i>
                                Previous
                            </button>
                            <button
                                type="button"
                                class="custom_btn next_btn"
							@click.prevent="next(quote_request.bProject)"
                            >
                                Next
                                <i class="next_arrow">⟶</i>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- ~./Step three -->

                <!-- Step four starts here -->
                <div class="step_form three new_step-form" v-if="step === 'b-project-type'">
                    <div class="step_form_top step-title">
                        <h2>Type of Project</h2>
                    </div>
                    <div class="step_form_main step_three">
                        <div class="group_windows_list">
                            <div
                                v-for="(value, key) in this.b_project_type"
                                :key="key"
                                :data-key="key"
                                @click="selectTypes(value.id, 4, 0)"
                                :class="{
                                    'group_windows radio checked':
                                        value.id == quote_request.bProjectType,
                                    'group_windows radio': value.id != quote_request.bProjectType,
                                }"
                            >
                                <label
                                    ><span>{{ value.name }}</span></label
                                >
                            </div>
                        <div class="form-error mt-2" v-show="empty_value">
							Please select an option
						</div>
                        </div>
                        <div class="button_cont">
                            <button class="no-x-margin" type="button" @click.prevent="prev()">
                                <i class="prev_arrow">⟵</i>
                                Previous
                            </button>
                            <button
                                type="button"
                                class="custom_btn next_btn"
							@click.prevent="next(quote_request.bProjectType)"
                            >
                                Next
                                <i class="next_arrow">⟶</i>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- ~./Step three -->

                <div class="step_form three new_step-form" v-if="showHomesBuiltAmountQuestion && step === 'homes-built-per-year'">
                    <div class="step_form_top step-title">
                        <h2>How many homes do you build per year?</h2>
                    </div>
                    <div class="step_form_main step_three">
                        <div class="group_windows_list my-4 ml-2">
                            <div class="group_windows_list my-4">
                                <div
                                    v-for="(value, key) in this.homes_built_per_year"
                                    :key="key"
                                    :data-key="key"
                                    @click="selectTypes(value.id, 5, 0)"
                                    :class="{
								'group_windows radio checked':
									value.id == quote_request.homes_built_per_year,
								'group_windows radio': value.id != quote_request.homes_built_per_year,
							}"
                                >
                                    <label
                                    ><span>{{ value.name }}</span></label
                                    >
                                </div>
                                <div class="form-error mt-2" v-show="empty_value">
                                    Please select an option
                                </div>
                            </div>
                        </div>
                        <div class="button_cont pt-4">
                            <button type="button" @click.prevent="prev()">
                                <i class="prev_arrow">⟵</i>
                                Previous
                            </button>
                            <button
                                type="button"
                                class="custom_btn next_btn"
                                @click.prevent="next(quote_request.homes_built_per_year)"
                            >
                                <i class="next_arrow">⟶</i>
                                Next
                            </button>
                        </div>
                    </div>
                </div>

              <!-- Step four starts here -->
              <div class="step_form two new_step-form" v-if="step === 'project-location'">
                <div class="step_form_top step-title">
                  <h2>Project location</h2>
                </div>
                <div class="step_form_main">
                  <div class="step-subtitle">
                    What is the location of your project?
                  </div>
                  <div class="group_windows_list">
                    <!-- usa by default -->
                    <div
                      class="group_windows radio"
                      :class="{'checked':quote_request.projectDetails.unitedStatesSelected === 1 }"
                      @click="selectProjectLocationCountry(1)"
                    >
                      <label><span>United States</span></label>
                    </div>
                    <div
                      class="group_windows radio"
                      :class="{
                          'checked':quote_request.projectDetails.unitedStatesSelected === 0
                        }"
                      @click="selectProjectLocationCountry(0)"
                    >
                      <label><span>Other</span></label>
                    </div>
                  </div>

                  <div v-if="showOtherCountries">
                    <div class="field-container field-country ml-2 mb-2" style="width: 75%;">
                      <label class="if-not-mobile contact-label placeholder"
                      ><span>Country</span></label
                      >
                      <select v-model="quote_request.projectDetails.country" @change="onProjectLocationCountryChange" class="steps-select">
                        <option
                          v-for="item in this.countriesOutsideUS"
                          :key="item"
                          :value="item"
                        >
                          {{ item }}
                        </option>
                      </select>
                    </div>
                    <div class="form-error mt-1 ml-2" v-if="quote_request.projectDetails.country === ''">
                      Please select a country
                    </div>
                  </div>

                  <div v-if="showProjectLocationZip" class="field-container field-zip mt-2">
                    <label class="if-not-mobile contact-label placeholder">
                      <span class="ml-2">Zip Code</span>
                    </label>
                    <input
                      id="project-location-zip"
                      type="text"
                      v-model="quote_request.projectDetails.zip"
                      placeholder="Postal Code"
                      class="steps-textbox input-zip mb-0"
                      @input="validateProjectLocationZipCode"
                    />
                    <label v-show="false" class="if-mobile contact-label placeholder">
                      <span>Zip Code</span>
                    </label>
                    <div v-if="projectLocationZipIsInvalid" class="mt-1 ml-2" :class="{ 'form-error': projectLocationZipIsInvalid != '' }">
                      Invalid Zip Code Format
                    </div>
                    <div v-if="empty_value" class="form-error mt-1 ml-2">
                      Zip code is required
                    </div>
                  </div>

                  <div class="field-container mt-2">
                    <label for="project-address" class="if-not-mobile contact-label placeholder">
                      <span class="ml-2">Project Address</span>
                    </label>
                    <input
                      id="project-address"
                      type="text"
                      v-model="quote_request.projectDetails.address"
                      placeholder="Project Address"
                      class="steps-textbox input-zip mb-0"
                      maxlength="500"
                    />
                    <label v-show="false" class="if-mobile contact-label placeholder">
                      <span>Project Address</span>
                    </label>
                  </div>

                  <div class="field-container mt-2">
                    <label for="project-name" class="if-not-mobile contact-label placeholder">
                      <span class="ml-2">Project Name</span>
                    </label>
                    <input
                      id="project-name"
                      type="text"
                      v-model="quote_request.projectDetails.name"
                      placeholder="Project Name"
                      class="steps-textbox input-zip mb-0"
                      maxlength="255"
                    />
                    <label v-show="false" class="if-mobile contact-label placeholder">
                      <span>Project Name</span>
                    </label>
                  </div>

                  <div class="button_cont mt-5">
                    <button type="button" @click.prevent="prev()">
                      <i class="prev_arrow">⟵</i>
                      Previous
                    </button>
                    <button
                      type="button"
                      class="custom_btn next_btn"
                      @click.prevent="next(quote_request.projectDetails)"
                    >
                      <i class="next_arrow">⟶</i>
                      Next
                    </button>
                  </div>
                </div>
              </div>

                <!-- Step four starts here -->
                <div class="step_form three new_step-form" v-if="step === 'number-windows'">
                    <div class="step_form_top step-title">
                        <h2>Estimate Number Of Windows</h2>
                    </div>
                    <div class="step_form_main step_three">
                        <div class="step_form_main">
                            <input
                                type="text"
                                value=""
                                v-model="quote_request.bWindowAmount"
                                @input="sanitizeEstimateNumberOfWindows"
                                placeholder="Window Amount"
                                class="steps-textbox"
                                maxlength="5"
                            />
                        </div>
                    <div class="form-error mt-2" v-show="empty_value">
							Please select an option
						</div>
                        <div class="button_cont">
                            <button class="no-x-margin" type="button" @click.prevent="prev()">
                                <i class="prev_arrow">⟵</i>
                                Previous
                            </button>
                            <button
                                type="button"
                                class="custom_btn next_btn"
							@click.prevent="next(quote_request.bWindowAmount)"
                            >
                                Next
                                <i class="next_arrow">⟶</i>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- ~./Step three -->

                <div class="step_form two new_step-form" v-if="step === 'number-doors'">
                    <div class="step_form_top step-title">
                        <h2>Estimate Number Of Doors</h2>
                    </div>
                    <div class="step_form_main step_three">
                        <div class="step_form_main">
                            <input
                                type="text"
                                value=""
                                v-model="quote_request.bDoorAmount"
                                @input="sanitizeEstimateNumberOfDoors"
                                placeholder="Door Amount"
                                class="steps-textbox"
                                maxlength="5"
                            />
                        </div>
                    <div class="form-error mt-2" v-show="empty_value">
							Please select an option
						</div>
					<div class="button_cont">
                        <button class="no-x-margin" type="button" @click.prevent="prev()">
                            <i class="prev_arrow">⟵</i>
                            Previous
                        </button>
						<button
							type="button"
							class="custom_btn next_btn"
							@click.prevent="next(quote_request.bDoorAmount)"
						>
							Next
							<i class="next_arrow">⟶</i>
						</button>
					</div>
				</div>
			</div>

            <div class="step_form three new_step-form" v-if="step === 'frame-preference'">
                <div class="step_form_top step-title">
                    <h2>Do you have a preference for frames?</h2>
                </div>
                <div class="step_form_main step_three">
                    <div class="group_windows_list">
                        <div
                            @click="selectFramePreference('aluminum')"
                            :class="{
                                'group_windows group_windows_multi radio checked':
                                    quote_request.framePreference.aluminum == '1',
                                'group_windows group_windows_multi radio':
                                    quote_request.framePreference.aluminum != '1'
                            }"
                        >
                            <label><span>Aluminum</span></label>
                        </div>
                        <div
                            @click="selectFramePreference('vinyl')"
                            :class="{
                                'group_windows group_windows_multi radio checked':
                                    quote_request.framePreference.vinyl == '1',
                                'group_windows group_windows_multi radio':
                                    quote_request.framePreference.vinyl != '1'
                            }"
                        >
                            <label><span>Vinyl</span></label>
                        </div>
                        <div
                            @click="selectFramePreference('not_sure')"
                            :class="{
                                'group_windows group_windows_multi radio checked':
                                    quote_request.framePreference.not_sure == '1',
                                'group_windows group_windows_multi radio':
                                    quote_request.framePreference.not_sure != '1'
                            }"
                        >
                            <label><span>Not sure</span></label>
                        </div>
                    </div>
                        <div class="button_cont">
                            <button class="no-x-margin" type="button" @click.prevent="prev()">
                                <i class="prev_arrow">⟵</i>
                                Previous
                            </button>
                            <button
                                type="button"
                                class="custom_btn next_btn"
                                @click.prevent="next()"
                            >
                                Next
                                <i class="next_arrow">⟶</i>
                            </button>
                        </div>
                    </div>
                </div>

			<div class="step_form two new_step-form" v-if="step === 'additional-info'">
                    <div class="step_form_top step-title">
                        <h2>
                            If you have additional information please enter below to best serve
                            you
                        </h2>
                    </div>
                    <div class="step_form_main step_two">
                        <div class="group_windows_list">
                            <div class="step_form_main additional_info_fields">
                                <textarea
                                    class="steps-textbox"
                                    v-model="quote_request.bAdditional"
                                    placeholder="Additional Information"
                                >
                                </textarea>
                            </div>
                        </div>
                        <div class="button_cont">
                            <button class="no-x-margin" type="button" @click.prevent="prev()">
                                <i class="prev_arrow">⟵</i>
                                Previous
                            </button>
                            <button
                                type="button"
                                class="custom_btn next_btn"
                                @click.prevent="next()"
                            >
                                Next
                                <i class="next_arrow">⟶</i>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="step_form two new_step-form" v-if="step === 'special-financing'">
                    <div class="step_form_top step-title">
                        <h2>Special financing</h2>
                    </div>
                    <div class="step_form_main">
                        <div class="step-subtitle">
                            Are you interested in special financing that allows you to pay for
                            this project over time?
                        </div>
                        <div class="group_windows_list">
                            <div
							class="group_windows radio"
							:class="{'checked':quote_request.interested_financing === 1}"
                                @click="selectSpecialFinancing(1)"
                            >
                                <label><span>Yes</span></label>
                            </div>
                            <div
							class="group_windows radio"
							:class="{'checked':quote_request.interested_financing === 0}"
                                @click="selectSpecialFinancing(0)"
                            >
                                <label><span>No</span></label>
                            </div>
                        <div class="form-error mt-2" v-show="empty_value">
							Please select an option
						</div>
                        </div>
                        <div class="button_cont">
                            <button class="no-x-margin" type="button" @click.prevent="prev()">
                                <i class="prev_arrow">⟵</i>
                                Previous
                            </button>
                            <button
                                type="button"
                                class="custom_btn next_btn"
							@click.prevent="next(quote_request.interested_financing)"
                            >
                                Next
                                <i class="next_arrow">⟶</i>
                            </button>
                        </div>
                    </div>
                </div>

                <!-- Step four starts here -->
                <div
                    class="step_form step_form_field four new_step-form"
				v-if="step === 'personal-info-form'"
                >
                    <div class="step_form_top step-title">
                        <h2>How can we contact you?</h2>
                    </div>
                    <div class="step_form_main personal-info-form">
                        <div class="step-field-group">
                            <label class="contact-label"><span>First Name</span></label>
                            <div class="field-container">
                                <input
                                    type="text"
                                    value=""
                                    v-model="quote_request.firstName"
                                    placeholder="First Name"
                                    class="steps-textbox"
                                />
                                <div
                                    :class="{
                                        'form-error': errors.firstName != '',
                                    }"
                                >
                                    {{ this.errors.firstName }}
                                </div>
                            </div>
                        </div>

                        <div class="step-field-group">
                            <label class="contact-label"><span>Last Name</span></label>
                            <div class="field-container">
                                <input
                                    type="text"
                                    value=""
                                    v-model="quote_request.lastName"
                                    placeholder="Last Name"
                                    class="steps-textbox"
                                />
                                <div :class="{ 'form-error': errors.lastName != '' }">
                                    {{ this.errors.lastName }}
                                </div>
                            </div>
                        </div>
                        <div class="step-field-group">
                            <label class="contact-label"><span>Email</span></label>
                            <div class="">
                                <input
                                    type="email"
                                    v-model="quote_request.email"
                                    value=""
                                    placeholder="Email"
                                    class="steps-textbox"
                                />
                                <div :class="{ 'form-error': errors.email != '' }">
                                    {{ this.errors.email }}
                                </div>
                            </div>
                        </div>

                        <div class="step-field-group">
                            <label class="contact-label"><span>Phone Number</span></label>
                            <div class="">
                                <input
                                    type="tel"
                                    v-model="quote_request.phone"
                                    value=""
                                    placeholder="Phone Number"
                                    @input="sanitizePhoneNumber"
                                    class="steps-textbox"
                                />
                                <div :class="{ 'form-error': errors.phone != '' }">
                                    {{ this.errors.phone }}
                                </div>
                            </div>
                        </div>

                        <div class="step-field-group mt-4">
                            <label class="contact-label"><span>Address</span></label>
                            <div class="field-container">
                                <input
                                    type="text"
                                    v-model="quote_request.address1"
                                    value=""
                                    placeholder="Address"
                                    class="steps-textbox input-address"
                                    @change="shouldConfirmAddress = true; addressConfirmed = false;"
                                />
                                <div :class="{ 'form-error': errors.address1 != '' }">
                                    {{ this.errors.address1 }}
                                </div>
                            </div>
                        </div>

                        <div class="step-field-group">
                            <label class="contact-label"><span>City</span></label>
                            <div class="field-container">
                                <input
                                    type="text"
                                    v-model="quote_request.city"
                                    value=""
                                    placeholder="City"
                                    class="steps-textbox"
                                />
                                <div :class="{ 'form-error': errors.city != '' }">
                                    {{ this.errors.city }}
                                </div>
                            </div>
                        </div>

                        <div class="step-field-group">
                            <label class="contact-label"><span>State</span></label>
                            <div
                                class="field-container"
                                v-if="quote_request.country == this.default_country"
                            >
                                <select
                                    v-model="quote_request.state"
                                    placeholder="State"
                                    class="steps-select"
                                >
                                    <option value="" disabled selected>Select State</option>
                                    <option
                                        v-for="(item, key) in this.states"
                                        :key="key"
                                        :value="item.name"
                                    >
                                        {{ item.name }}
                                    </option>
                                </select>

                                <div :class="{ 'form-error': errors.state != '' }">
                                    {{ this.errors.state }}
                                </div>
                            </div>
                            <div class="field-container" v-else>
                                <input
                                    type="text"
                                    v-model="quote_request.state"
                                    value=""
                                    placeholder="State"
                                    class="steps-textbox"
                                />

                                <div :class="{ 'form-error': errors.state != '' }">
                                    {{ this.errors.state }}
                                </div>
                            </div>
                        </div>

                        <div class="step-field-group">
                            <label class="contact-label"><span>Postal Code</span></label>
                            <div class="field-container">
                                <input
                                    type="text"
                                    v-model="quote_request.zip"
                                    value=""
                                    placeholder="Postal Code"
                                    class="steps-textbox"
                                    maxlength="5"
                                    @input="sanitizeZipCode"
                                />

                                <div :class="{ 'form-error': errors.zip != '' }">
                                    {{ this.errors.zip }}
                                </div>
                            </div>
                        </div>

                        <div class="step-field-group">
                            <label class="contact-label"><span>Country</span></label>
                            <div class="field-container">
                                <select v-model="quote_request.country" class="steps-select">
                                    <option
                                        v-for="item in this.countries"
                                        :key="item"
                                        :value="item"
                                    >
                                        {{ item }}
                                    </option>
                                </select>

                                <div :class="{ 'form-error': errors.country != '' }">
                                    {{ this.errors.country }}
                                </div>
                            </div>
                        </div>

                        <!-- <div class="step-field-group mt-4">
                            <div class="group_windows_list">
                                <label class="text-label">Preferred Contact Method</label>

                                <div
                                    :class="{
                                        'group_windows radio checked':
                                            'E' == quote_request.contactMethod,
                                        'group_windows radio': 'E' != quote_request.contactMethod,
                                    }"
                                    @click="selectTypes('E', 10, 1)"
                                >
                                    <label>
                                        <span>Email</span>
                                    </label>
                                </div>
                                <div
                                    :class="{
                                        'group_windows radio checked':
                                            'P' == quote_request.contactMethod,
                                        'group_windows radio': 'P' != quote_request.contactMethod,
                                    }"
                                    @click="selectTypes('P', 10, 1)"
                                >
                                    <label>
                                        <span>Phone</span>
                                    </label>
                                </div>
                            </div>
                            <div :class="{ 'form-error': errors.contactMethod != '' }">
                                {{ this.errors.contactMethod }}
                            </div>
                        </div> -->

                        <div class="step-field-group">
                            <div class="group_windows_list">
                                <div
                                    :class="{
                                        'group_windows group_windows_multi radio checked':
                                            'Y' == quote_request.newsletter,
                                        'group_windows group_windows_multi radio':
                                            'Y' != quote_request.newsletter,
                                    }"
                                    @click="selectTypes('Y', 10, 2)"
                                >
                                    <label>
                                        <span>
                                            Please send me email updates about new products, news, and
                                            promotions.
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <input type="hidden" id="window_amount" v-model="windowAmount" />
                        <input type="hidden" id="door_amount" v-model="doorAmount" />
                        <input type="hidden" id="cluid" v-model="cluid" />

                        <div class="button_cont">
                            <button class="no-x-margin" type="button" @click.prevent="prev()">
                                <i class="prev_arrow">⟵</i>
                                Previous
                            </button>
                            <button
                                type="button"
                                class="custom_btn next_btn"
                                @click.prevent="postLead()"
                            >
                                submit
                                <i class="next_arrow">⟶</i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal name="confirm-address" :clickToClose="false" height="auto">
            <ConfirmAddressForm :quote_request="this.quote_request" v-on:address-confirmed="handleAddressConfirmed" :url_prepend="url_prepend"></ConfirmAddressForm>
        </modal>
    </div>
</template>
<style scoped>
	#closeModal {
		position: absolute;
		right: 10px;
		top: 10px;
        background: transparent;
        border: 0;
	}
</style>
<script>
  import Loading from "vue-loading-overlay";
	import "vue-loading-overlay/dist/vue-loading.css";
	import _ from "lodash";
  import countryPostalRegexes from "../countryPostalCodeRegexs.json";
  import googleMap from "./GoogleMap";
  import ConfirmAddressForm from "./ConfirmAddressForm.vue";

	export default {
		name: "QuoteStepForm",
		props: {
			dealer: "",
			service_types: {},
			home_types: {},
			new_buy_plans: {},
			window_amount: {},
			door_amount: {},
			window_importance: {},
			interested_products: {},
			lead_types: {},
			business_types: {},
			project_conditions: {},
			project_types: {},
			states: {},
			countries: {},
			default_country: "",
			brand: "",
			source_id: "",
			interested_financing: "",
			redirect_thankyou: "",
			brand_ga: "",
			iframe_domain: "",
			show_close: false,
			data_form: "",
            frontend_url: "",
            add_wrapper: "",
            client_ip: "",
            user_agent: "",
            homes_built_per_year: "",
            brand_object: {
                type: Object,
                default: null
            },
		},
		data() {
			return this.initData();
		},
		created() {
			// if (this.dealer) {
			//     this.initApiToken();
			// }
		},
        watch: {
            showHomesBuiltAmountQuestion(newValue, oldValue) {
                if (newValue === false) {
                    return this.quote_request.homes_built_per_year = null;
                }
            },
            projectLocationCountry(newValue) {
              if (newValue) {
                this.quote_request.projectDetails.countryCode = countryPostalRegexes.find(i => i.name === newValue).abbrev;
                this.autocompleteOptions.componentRestrictions.country = this.quote_request.projectDetails.countryCode;
              } else {
                this.quote_request.projectDetails.countryCode = "";
                this.autocompleteOptions.componentRestrictions.country = "US";
              }
            },
            finalStepCountry(newValue) {
              if (this.autocomplete === null) return;

              if (newValue) {
                this.autocomplete.setComponentRestrictions({country: countryPostalRegexes.find(i => i.name === newValue).abbrev});
              } else {
                this.autocomplete.setComponentRestrictions({country: "US"});
              }
            },
        },
		components: {
			Loading,
            ConfirmAddressForm
		},
		methods: {
            referralInfo() {
                return {
                    full_url: window.location.href
                };
            },
      sanitizeZipCode() {
        const _t = this;
        const data = countryPostalRegexes;
        let found = data.find(data => data.name === _t.quote_request.country);
        if (found && found.hasOwnProperty('regex')) {
          let match = _t.quote_request.zip.match(found.regex);
          let passessRegexExact = match && _t.quote_request.zip === match[0];

          this.zipIsInvalid = (passessRegexExact === null || passessRegexExact === false);
          if (this.zipIsInvalid) {
            this.errors.zip = 'Invalid Zip Code Format';
          } else {
            this.errors.zip = '';
          }
        }
      },
			sanitizePhoneNumber() {
				var tmp = this.quote_request.phone
					.replace(/\D/g, "")
					.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
				this.quote_request.phone = !tmp[2]
					? tmp[1]
					: "(" + tmp[1] + ") " + tmp[2] + (tmp[3] ? "-" + tmp[3] : "");
			},
			sanitizeProjectLocationZip() {
				this.quote_request.bProjectLocationZip = this.quote_request.bProjectLocationZip.replace(
					/\D/g,
					""
				);
			},
			sanitizeEstimateNumberOfDoors() {
				this.quote_request.bDoorAmount = this.quote_request.bDoorAmount.replace(
					/\D/g,
					""
				);
			},
			sanitizeEstimateNumberOfWindows() {
				this.quote_request.bWindowAmount = this.quote_request.bWindowAmount.replace(
					/\D/g,
					""
				);
			},
      initGoogleApi(callback) {
        if (typeof google !== "undefined" && google.maps && google.maps.places) {
          callback.apply(this, arguments);
          return;
        }
        let el = document.createElement('script');
        el.src = "https://maps.googleapis.com/maps/api/js?key=" + process.env.MIX_GMAP_API_KEY + "&libraries=places&language=en";
        document.body.appendChild(el);
        let _t = this;
        return setTimeout(function(){
          callback.apply(this, arguments);
        }, 2000);
      },
      initAddressAutoComplete: function () {
        var _t = this;
        this.initGoogleApi(function(){
          var input = document.getElementsByClassName("input-address")[0];
          _t.autocomplete = new google.maps.places.Autocomplete(input, _t.autocompleteOptions);
          google.maps.event.addListener(_t.autocomplete, "place_changed", function () {
            var place = _t.autocomplete.getPlace();
            if (place && place.address_components) {
              _t.shouldConfirmAddress = false;
              i_t.quote_request.confirmation_popup_gmap_suggestion_selected = 'no';
              var address_components = place.address_components;
              let outStreetNumber = "";
              let outStreetName = "";
              let outCity;
              let outZip = "";
              let outState = "";
              let outCountry = "";
              for (var i = 0; i < address_components.length; i++) {
                let tComponent = address_components[i];
                let componentType = tComponent.types;
                if (!componentType) return;
                if (componentType.indexOf("street_number") > -1) {
                  outStreetNumber = tComponent.long_name;
                } else if (componentType.indexOf("route") > -1) {
                  outStreetName = tComponent.long_name;
                } else if (componentType.indexOf("locality") > -1) {
                  outCity = tComponent.long_name;
                } else if (componentType.indexOf("postal_code") > -1) {
                  outZip = tComponent.long_name;
                } else if (
                  componentType.indexOf("administrative_area_level_1") > -1
                ) {
                  outState = tComponent.long_name;
                } else if (componentType.indexOf("country") > -1) {
                  outCountry = tComponent.long_name;
                }
              }
              _t.quote_request.address1 = outStreetNumber + " " + outStreetName;
              _t.quote_request.city = outCity;
              // Auto filled on select
              _t.quote_request.zip = outZip;
              if (outCountry && _t.countries.indexOf(outCountry) > -1) {
                _t.quote_request.country = outCountry;
                _t.quote_request.state = outState;
              }
            }
          });
        });
      },
			postLead() {
				var $this = this;
				if (window.CLabsgbVar !== undefined) {
					$this.cluid = window.CLabsgbVar.generalProps.uid;
				}
				this.checkForm();
				if (this.has_errors) {
					return;
				}

                if (typeof ORIBI !== "undefined"){
                    ORIBI.api("setUserEmail", this.quote_request.email);
                }

                if (this.shouldShowAddressConfirmationPopup) {
                    this.quote_request.confirmation_popup_gmap_suggestion_selected = 'no';
                    this.$modal.show('confirm-address', {
                        full_wrapper_classes: 'wtb-wrapper',
                    })
                    return;
                }


				$this.isLoading = true;
                window.dataLayer.push({
                    'event':'leadSubmission',
                    'leadData':this.quote_request
                });
                console.log(window.dataLayer);
				axios
					.post(this.url_prepend + "/api/lead", $this.quote_request)
					.then((response) => {
						if (response.data.success) {
							$this.$emit("close");

							// Redirect and break;
							setTimeout(function () {
								if ($this.redirect_thankyou) {
									parent.location = $this.redirect_thankyou;
								}
							}, 400);
							if ($this.redirect_thankyou) {
								return;
							}

							// Code below will not execute unless falsy .redirect_thankyou

							setTimeout(() => {
								parent.postMessage({ task: "scroll_top" }, "*");
							}, 50);
							$this.$scrollTo($this.$refs.pageTop);
							$this.isLoading = false;
							Swal.fire({
								icon: "success",
								title: "Thank you for your interest in our products.",
								text: "An authorized dealer will contact you shortly.",
								showConfirmButton: false,
								timer: 10000,
								allowEnterKey: false,
								allowEscapeKey: false,
								allowOutsideClick: false,
								position: "top",
							});
							setTimeout(function () {
								$this.step = 'lead-types';
							}, 10000);
							$this.quote_request = {
								serviceType: 1,
								newBuyPlan: 1,
								homeType: 1,
								windowAmount: 1,
								doorAmount: 1,
								leadType: "H",
								windowImportance: ["1"],
								interestedProducts: ["1"],
								oInterestedProducts: "",
								oWindowAmount: "",
								oWindowImportance: "",
								firstName: "",
								lastName: "",
								email: "",
								phone: "",
								address1: "",
								city: "",
								state: "",
								zip: "",
								country: this.default_country,
								dealer_id: $this.dealer ? $this.dealer.id : "",
								bDescription: "1",
								bProject: "1",
								bProjectType: "1",
								bLocation: "",
								bWindowAmount: "1",
								bDoorAmount: "1",
								bAdditional: "",
								newsletter: "Y",
								contactMethod: "",
								brand: this.brand,
								source_id: this.source_id,
								interested_financing: this.interested_financing,
								interested_installation: "yes",
								redirect_thankyou: this.redirect_thankyou,
                                referral_info: this.referralInfo()
							};
							$this.hasOInterestedProducts = 0;
							$this.hasOWindowAmount = 0;
							$this.hasOWindowImportance = 0;
							$this.address = "";
							$this.errors = $this.validations();
							$this.has_errors = false;
						}
					})
					.catch((error) => {
						console.log(error);
						$this.$scrollTo($this.$refs.pageTop);
						$this.isLoading = false;
						Swal.fire("Error Processing Request!", "Please try again", "warning");

						if (error.response.status == 401) {
							this.initApiToken();
						}
					});
			},
            prev() {
                this.empty_value = false;
                this.step = this.previousStep;
            },
			next(value) {
					if (value === "") {
						this.empty_value = true;
						return;
					}

				if (typeof value == "object") {
					if (Array.isArray(value)) {
						if (!value.length) {
							this.empty_value = true;
							return;
						}
					}else{
            if(value.aluminum == 0 && value.vinyl == 0 && value.not_sure == 0) {
              this.empty_value = true;
              return;
            }

            if (value.country === '') {
              this.empty_value = true;
              return;
            }

            if (!this.countryNamesWithoutZipRegex.includes(value.country)) {
              if (value.zip == '') {
                this.empty_value = true;
                return;
              }

              if (this.projectLocationZipIsInvalid) {
                return;
              }
            }
					}
				}

				this.empty_value = false;
                this.step = this.nextStep;

                // Initialize autocomplete when in form (always last step)
                if (this.currentStepIndex === this.stepOrder.length - 1) {
                    setTimeout(this.initAddressAutoComplete, 400);
                }
			},
            selectFramePreference(id) {
                let currentValue = this.quote_request.framePreference[id];
                for (var name in this.quote_request.framePreference) {
                    this.quote_request.framePreference[name] = 0;
                }
                this.quote_request.framePreference[id] = currentValue == '1' ? '0' : '1';
            },
			selectTypes(id, step, extra) {
				if (step == "1") {
					this.quote_request.leadType = id;
				}

				if (this.quote_request.leadType == "H") {
					if (step == "2") {
						this.quote_request.serviceType = id;
					} else if (step == "3") {
						this.quote_request.homeType = id;
					} else if (step == "4") {
						var pos = this.quote_request.interestedProducts.indexOf(
							id.toString()
						);

						if (pos != -1) {
							this.quote_request.interestedProducts.splice(pos, 1);
						} else {
							this.quote_request.interestedProducts.push(id.toString());
						}

						// this.quote_request.oInterestedProducts = "";
					} else if (step == "5") {
						this.quote_request.windowAmount = id;
						console.log(this.quote_request.windowAmount.length);
						if (this.quote_request.windowAmount.length == 1) {
							this.hasOWindowAmount = 0;
						}
						// this.quote_request.oWindowAmount = "";
					} else if (step == "6") {
						this.quote_request.doorAmount = id;
					} else if (step == "8") {
						this.quote_request.interested_installation = id;
					} else if (step == "9") {
						var pos = this.quote_request.windowImportance.indexOf(id.toString());

						if (pos != -1) {
							this.quote_request.windowImportance.splice(pos, 1);
						} else {
							this.quote_request.windowImportance.push(id.toString());
						}
						// this.quote_request.oWindowImportance = "";
					} else if (step == "10") {
						this.quote_request.newBuyPlan = id;
					} else if (step == "12") {
						if (extra == "1") {
							this.quote_request.contactMethod = id;
						}

						if (extra == "2") {
							this.quote_request.newsletter =
								this.quote_request.newsletter == "Y" ? "N" : "Y";
						}
					}
				} else {
					if (step == "2") {
						this.quote_request.bDescription = id;
					} else if (step == "3") {
						this.quote_request.bProject = id;
					} else if (step == "4") {
						this.quote_request.bProjectType = id;
					} else if (step == "5") {
                        this.quote_request.homes_built_per_year = id;
                    } else if (step == "10") {
						if (extra == "1") {
							this.quote_request.contactMethod = id;
						}

						if (extra == "2") {
							this.quote_request.newsletter =
								this.quote_request.newsletter == "Y" ? "N" : "Y";
						}
					}
				}
			},
      selectProjectLocationCountry(value) {
        this.quote_request.projectDetails.zip = "";
        this.quote_request.projectDetails.country = "";

        this.quote_request.projectDetails.unitedStatesSelected = value;

        if (value === 1) {
          this.quote_request.projectDetails.country = 'United States';
          this.quote_request.projectDetails.countryCode = 'US';
        }

        this.projectLocationZipIsInvalid = false;
        return this.showOtherCountries = value !== 1;
      },
			selectOthers(step) {
				var $this = this;
				if ($this.quote_request.leadType == "H") {
					if (step == "4") {
						if (
							$this.hasOInterestedProducts == 1 &&
							$this.quote_request.oInterestedProducts.length <= 0
						) {
							$this.hasOInterestedProducts = 0;
						} else {
							$this.hasOInterestedProducts = 1;
						}
					} else if (step == "7") {
						if (
							$this.hasOWindowImportance == 1 &&
							$this.quote_request.oWindowImportance.length <= 0
						) {
							$this.hasOWindowImportance = 0;
						} else {
							$this.hasOWindowImportance = 1;
						}
					}
				}
			},
			setOthers(step) {
				var $this = this;
				if ($this.quote_request.leadType == "H") {
					if (step == "4") {
						if ($this.hasOInterestedProducts == 1) {
							$this.hasOInterestedProducts = 0;
							$this.quote_request.oInterestedProducts = "";
						} else {
							$this.hasOInterestedProducts = 1;
						}
					} else if (step == "5") {
						$this.hasOWindowAmount = 1;
						$this.quote_request.windowAmount = "";
					} else if (step == "7") {
						if ($this.hasOWindowImportance == 1) {
							$this.hasOWindowImportance = 0;
							$this.quote_request.oWindowImportance = "";
						} else {
							$this.hasOWindowImportance = 1;
						}
					}
				}
			},
			formatResult(place) {
				var filter = {
					street_number: "short_name",
					route: "long_name",
					locality: "long_name",
					administrative_area_level_1: "short_name",
					administrative_area_level_2: "county",
					country: "long_name",
					postal_code: "short_name",
				};

				let returnData = {};
				for (let i = 0; i < place.address_components.length; i++) {
					let addressType = place.address_components[i].types[0];

					if (filter[addressType]) {
						let val = place.address_components[i][filter[addressType]];
						returnData[addressType] = val;
					}
				}

				returnData["latitude"] = place.geometry.location.lat();
				returnData["longitude"] = place.geometry.location.lng();
				return returnData;
			},
			getAddressData: function (place) {
				var state = "";
				var city = "";
				var country = "";
				var zip = "";
				var address1 = "";
				var addressData = null;

				if (place.address_components !== undefined) {
					addressData = this.formatResult(place);
				}

				for (var i = 0; i < place.address_components.length; i++) {
					if (place.address_components[i].types[0] == "locality") {
						city = place.address_components[i];
					}
					if (
						place.address_components[i].types[0] == "administrative_area_level_1"
					) {
						state = place.address_components[i];
					}
					if (place.address_components[i].types[0] == "country") {
						country = place.address_components[i];
					}
				}

				this.quote_request.city =
					typeof city.long_name !== "undefined" ? city.long_name : "";
				this.quote_request.state =
					typeof state.long_name !== "undefined" ? state.long_name : "";
				this.quote_request.country =
					typeof country.long_name !== "undefined" ? country.long_name : "";
				this.quote_request.zip =
					typeof addressData.postal_code !== "undefined"
						? addressData.postal_code
						: "";
				this.quote_request.address1 =
					typeof addressData.route !== "undefined" ? addressData.route : "";
			},
			initData() {
				return {
					step: 'lead-types',
					isLoading: false,
					fullPage: false,
					cluid: "",
					quote_request: {
						serviceType: "",
						newBuyPlan: "",
						homeType: "",
						windowAmount: "",
						doorAmount: "",
						leadType: "H",
						windowImportance: [],
						interestedProducts: [],
            framePreference: {
                aluminum: 0,
                vinyl: 0,
                not_sure: 0
            },
						oInterestedProducts: "",
						oWindowAmount: "",
						oWindowImportance: "",
						firstName: "",
						lastName: "",
						email: "",
						phone: "",
						address1: "",
						city: "",
						state: "",
						zip: "",
						country: this.default_country,
						dealer_id: this.dealer ? this.dealer.id : "",
						bDescription: "",
						bName: "",
						bProject: "",
						bProjectType: "",
						bLocation: "",
						bWindowAmount: "",
						bDoorAmount: "",
						bAdditional: "",
						newsletter: "Y",
						contactMethod: "",
						brand: this.brand,
						source_id: this.source_id,
						interested_financing: "",
						interested_installation: "",
						redirect_thankyou: this.redirect_thankyou,
                        client_ip: this.client_up,
                        user_agent: this.user_agent,
                        referral_info: this.referralInfo(),
                        homes_built_per_year: "",
            projectDetails: {
              unitedStatesSelected: 1,
              country: "United States",
              countryCode: "US",
              zip: "",
              address: "",
              name: "",
            },
                    confirmation_popup_gmap_suggestion_selected: 'no',
					},
					address: "",
					errors: this.validations(),
					has_errors: false,
					empty_value: false,
					b_description: this.business_types,
					b_project: this.project_conditions,
					b_project_type: this.project_types,
					b_window_amount: "",
					b_door_amount: "",
					b_addional: "",
					hasOInterestedProducts: 0,
					hasOWindowAmount: 0,
					hasOWindowImportance: 0,
          showOtherCountries: false,
          projectLocationZipIsInvalid: false,
          zipIsInvalid: false,
          autocomplete: null,
          autocompleteOptions: {
            componentRestrictions: { country: "US" },
            strictBounds: true,
          },
          shouldConfirmAddress: false,
          addressConfirmed: false,
				};
			},
			validations() {
				return {
					firstName: "",
					lastName: "",
					email: "",
					phone: "",
					address1: "",
					city: "",
					zip: "",
					state: "",
				};
			},
			selectSpecialFinancing(value) {
				this.quote_request.interested_financing = value;
			},
			checkForm() {
				this.has_errors = false;
				this.errors = this.validations();

				if (
					(this.step == 'personal-info-form')
				) {
					if (this.quote_request.firstName == "") {
						this.errors.firstName = "First Name is required";
						this.has_errors = true;
					}

					if (this.quote_request.lastName == "") {
						this.errors.lastName = "Last Name is required";
						this.has_errors = true;
					}

					if (!this.quote_request.email) {
						this.errors.email = "Email is required";
						this.has_errors = true;
					} else if (!this.validEmail(this.quote_request.email)) {
						this.errors.email = "Valid email required";
						this.has_errors = true;
					}

					if (!this.quote_request.phone) {
						this.errors.phone = "Phone is required";
						this.has_errors = true;
					} else if (this.quote_request.phone.replace(/\D/g, "").length < 10) {
						this.errors.phone = "Enter a valid phone number";
						this.has_errors = true;
					}

                    if (["", " ", null].includes(this.quote_request.address1)) {
						this.errors.address1 = "Address is required";
						this.has_errors = true;
					}

					if (this.quote_request.city == "") {
						this.errors.city = "City is required";
						this.has_errors = true;
					}

					// Don't validate State if it's outside the US
					if (this.quote_request.country == this.default_country) {
						if (this.quote_request.zip == "") {
							this.errors.zip = "Postal Code is required";
							this.has_errors = true;
						}
						if (this.quote_request.state == "") {
							this.errors.state = "State is required";
							this.has_errors = true;
						}
					}

                    //if (this.quote_request.contactMethod == "") {
                        //this.errors.contactMethod = "Contact method is required";
                        //this.has_errors = true;
                    //}

          if (this.quote_request.country === 'United States' && (this.quote_request.phone.startsWith('(1') || this.quote_request.phone.startsWith(1))) {
            this.errors.phone = 'Phone cannot start with 1.';
            this.has_errors = true;
          }

          if  (this.zipIsInvalid) {
            this.errors.zip = "Invalid Zip Code Format";
            this.has_errors = true;
          }
				} else {
					this.has_errors = false;
					this.validations();
				}
			},
			validEmail(email) {
				var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				return re.test(email);
			},
			initApiToken() {
				axios
					.post(this.url_prepend + "/api/token/request", {
						dealer_id: this.dealer.id,
						secret: this.dealer.api_secret
							? this.dealer.api_secret
							: "dealer-secret",
					})
					.then(
						(response) => {
							if (response.data.success) {
								console.log(response.data.message);
							}
						},
						(error) => {
							console.log(error);
						}
					);
			},

      onProjectLocationCountryChange(e) {
        this.quote_request.projectDetails.zip = '';
        this.projectLocationZipIsInvalid = false;

        // Update country  on final step
        this.quote_request.country = this.quote_request.projectDetails.country;
      },

      validateProjectLocationZipCode: _.throttle(function (obj) {
        if (this.quote_request.projectDetails.zip === '') {
          this.projectLocationZipIsInvalid = false;
          return;
        }

        const _t = this;
        const data = countryPostalRegexes;
        let found = data.find(data => data.name === _t.quote_request.projectDetails.country);
        if (found && found.hasOwnProperty('regex')) {
          let match = _t.quote_request.projectDetails.zip.match(found.regex);
          let passessRegexExact = match && _t.quote_request.projectDetails.zip === match[0];

          this.projectLocationZipIsInvalid = (passessRegexExact === null || passessRegexExact === false);

          if (! this.projectLocationZipIsInvalid) {
            this.quote_request.zip =  this.quote_request.projectDetails.zip;
          }

          this.empty_value = false;
        }
      }, 150),
        handleAddressConfirmed(event) {
            if (event.gmapSuggestionSelected === true) {
                this.quote_request.address1 = event.gmapSuggestedAddress.address1;
                this.quote_request.city = event.gmapSuggestedAddress.city;
                this.quote_request.state = event.gmapSuggestedAddress.state;
                this.quote_request.zip = event.gmapSuggestedAddress.zip;
                this.quote_request.country = event.gmapSuggestedAddress.country;
                this.quote_request.county = event.gmapSuggestedAddress.county;
                this.quote_request.latitude = event.gmapSuggestedAddress.latitude;
                this.quote_request.longitude = event.gmapSuggestedAddress.longitude;
                this.quote_request.confirmation_popup_gmap_suggestion_selected = 'yes';
            } else {
                this.quote_request.confirmation_popup_gmap_suggestion_selected = 'no';
            }_

            this.addressConfirmed = true;
            this.$modal.hide('confirm-address');
            this.postLead();
        }
		},
		computed: {
			windowAmount() {
				if (this.quote_request.leadType == "H") {
					switch (this.quote_request.windowAmount) {
						case "1":
							return "1-2";
							break;
						case "2":
							return "3-5";
							break;
						case "3":
							return "6-9";
							break;
						case "4":
							return "10+";
							break;
						default:
							return "NA";
							break;
					}
				} else {
					return this.quote_request.bWindowAmount;
				}
			},
			doorAmount() {
				if (this.quote_request.leadType == "H") {
					switch (this.quote_request.doorAmount) {
						case "1":
							return "1-2";
							break;
						case "2":
							return "3+";
							break;
						case "3":
							return "Not Sure";
							break;
						case "4":
							return "I don't need any at the moment";
							break;
						default:
							return "NA";
							break;
					}
				} else {
					return this.quote_request.bDoorAmount;
				}
			},
            url_prepend() {
                return this.frontend_url ? this.frontend_url : '';
            },
            currentStepIndex: function() {
                return this.stepOrder.indexOf(this.stepOrder.find(el => {
                    return typeof el === "object" ? el.name === this.step : el === this.step
                }));
            },
            nextStep: function() {
                let index = this.currentStepIndex;

                if (index < 0) throw new Error("No such current step " + this.step + "; " + JSON.stringify(this.stepOrder));

                for (let i = index + 1; i < this.stepOrder.length; i++) {
                    let tryIndex = i;

                    if (typeof this.stepOrder[tryIndex] === "undefined") {
                        throw new Error("No such step index " + tryIndex + "; " + JSON.stringify(this.stepOrder));
                    }

                    if (typeof this.stepOrder[tryIndex] === "object") {
                        if (typeof this.stepOrder[tryIndex].when === "function") {
                            if (this.stepOrder[tryIndex].when()) {
                                return this.stepOrder[tryIndex].name;
                            }
                        } else {
                            return this.stepOrder[tryIndex].name;
                        }
                    } else {
                        return this.stepOrder[tryIndex];
                    }
                }

                throw new Error("No such next step after " + index + "; " + JSON.stringify(this.stepOrder));
            },
            previousStep: function() {
                let index = this.currentStepIndex;

                if (index < 0) throw new Error("No such current step " + this.step + "; " + JSON.stringify(this.stepOrder));

                for (let i = index - 1; i >= 0; i--) {
                    let tryIndex = i;

                    if (typeof this.stepOrder[tryIndex] === "undefined") {
                        throw new Error("No such step index " + tryIndex + "; " + JSON.stringify(this.stepOrder));
                    }

                    if (typeof this.stepOrder[tryIndex] === "object") {
                        if (typeof this.stepOrder[tryIndex].when === "function") {
                            if (this.stepOrder[tryIndex].when()) {
                                return this.stepOrder[tryIndex].name;
                            }
                        } else {
                            return this.stepOrder[tryIndex].name;
                        }
                    } else {
                        return this.stepOrder[tryIndex];
                    }
                }

                throw new Error("No such next step before " + index + "; " + JSON.stringify(this.stepOrder));
            },
            stepOrder: function(){
                if (this.quote_request.leadType === 'H') {
                    return [
                        'lead-types',
                        'service-types',
                        'home-types',
                        'interested-products',
                        {
                            name: 'window-amount',
                            when: () => {
                                return this.quote_request.interestedProducts.includes("1")
                            }
                        },
                        {
                            name: 'door-amount',
                            when: () => {
                                return this.quote_request.interestedProducts.includes("2");
                            }
                        },
                        'frame-preference',
                        'interested-installation',
                        'window-importance',
                        'new-buy-plans',
                        'interested-financing',
                        {
                          name: 'project-location',
                          when: () => {
                            return ![4, 7].includes(this.brand);
                          }
                        },
                        'personal-info-form'
                    ];
                } else if (this.quote_request.leadType === 'B') {
                    return [
                        'lead-types',
                        'profession',
                        'b-project',
                        'b-project-type',
                        ... this.showHomesBuiltAmountQuestion ? ['homes-built-per-year'] : [],
                        'project-location',
                        'number-windows',
                        'number-doors',
                        'frame-preference',
                        'additional-info',
                        'special-financing',
                        'personal-info-form'
                    ];
                }
            },

            showHomesBuiltAmountQuestion() {
                return this.quote_request.leadType === 'B'
                    && this.quote_request.bDescription === '2'
                    && this.quote_request.bProjectType === '3';
            },


            countriesOutsideUS() {
              return this.countries.filter(function (country) {
                return country !== 'United States';
              });
            },
            countryNamesWithoutZipRegex() {
              return countryPostalRegexes
                .filter(i => !i.hasOwnProperty('regex'))
                .map(i => i.name);
            },
            showProjectLocationZip() {
              return !this.countryNamesWithoutZipRegex.includes(this.quote_request.projectDetails.country);
            },
            projectLocationCountry() {
              return this.quote_request.projectDetails.country;
            },
            finalStepCountry() {
              return this.quote_request.country;
            },
            shouldShowAddressConfirmationPopup() {
                return this.brand_object?.force_lead_address_validation === 1
                    && this.shouldConfirmAddress === true
                    && this.addressConfirmed === false;
            }
		},
	};
</script>
