<template>
  <div class="initial-loader" :style="`color: ${brand_loading_hex_color}`">
    <div>
      <div class="three-preloader-icons">
        <svg class="preloader-icon" :style="`color: ${brand_loading_hex_color}`" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M496 480H480V64c0-35.35-28.65-64-64-64H96C60.65 0 32 28.65 32 64v416H16C7.164 480 0 487.2 0 496C0 504.8 7.164 512 16 512h480c8.836 0 16-7.164 16-16C512 487.2 504.8 480 496 480zM240 480H64V288h176V480zM240 256H64V64c0-17.67 14.33-32 32-32h144V256zM448 480h-176V288H448V480zM448 256h-176V32H416c17.67 0 32 14.33 32 32V256z"/></svg>
        <svg class="preloader-icon" :style="`color: ${brand_loading_hex_color}`" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M560 480H480V80C480 35.88 444.1 0 400 0h-224C131.9 0 96 35.88 96 80V480H16C7.164 480 0 487.2 0 496C0 504.8 7.164 512 16 512h544c8.836 0 16-7.164 16-16C576 487.2 568.8 480 560 480zM448 480H128V80C128 53.53 149.5 32 176 32h224C426.5 32 448 53.53 448 80V480zM384 232c-13.25 0-24 10.75-24 24S370.7 280 384 280S408 269.3 408 256S397.3 232 384 232z"/></svg>
        <svg class="preloader-icon" :style="`color: ${brand_loading_hex_color}`" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M137.6 160L144.6 159.8l4.734-4.969C178.7 124.1 217.4 103.8 258.3 97.89C267 96.63 273.1 88.5 271.8 79.77C270.6 71.02 262.5 64.84 253.7 66.23C207.8 72.89 164.4 94.8 130.9 128H16C7.156 128 0 135.2 0 144S7.156 160 16 160H137.6zM221.1 165.2C200.5 186.1 199.7 218.9 219.4 240.6C226.6 248.7 240.5 257.6 257.6 258.4c.875 .0313 1.734 .0625 2.594 .0625c12.94 0 25.05-4.844 35.19-14.08l76.47-71.19c6.469-6.016 6.844-16.14 .8281-22.61c-6.031-6.484-16.14-6.812-22.61-.8281C302.5 194 282.7 212.4 274.5 221.5L273.8 220.8c-4.516 4.094-9.5 5.797-14.62 5.703C251.9 226.1 245.6 221.9 243.1 219.2C235.8 211.1 234.3 196.9 244.4 187.2l93.28-87.3C340.5 97.36 343.9 96 347.4 96h8.172c49.13 0 98.47 21.58 135.4 59.2L495.7 160H624C632.8 160 640 152.8 640 144S632.8 128 624 128h-115c-42.33-40.75-97.86-64-153.4-64h-8.172c-11.52 0-22.64 4.359-31.47 12.42L221.5 164.8L221.1 165.2zM624 352h-124.7c-2.766-7.615-6.795-14.81-13.52-20.28l-108.5-88.14c-6.891-5.578-16.97-4.516-22.52 2.328c-5.562 6.859-4.531 16.94 2.328 22.52l108.5 88.11c6.125 5 7.062 14.06 2.109 20.19l-9.094 11.25c-4.938 6.156-14.08 7.031-20.03 2.312l-17.56-14.72l-40 49.25c-6.797 8.281-19.05 9.594-26.89 3.188l-16.97-14.75l-12.62-8.312l-10.55 10.53c-7.531 9.281-18.22 15.06-30.09 16.28c-11.98 1.188-23.58-2.281-33.2-10.06L160.4 352l-128.4-.1209C23.15 351.9 16 359.2 16 368S23.16 384 32 384h117.5l81.33 62.75C244.7 458 261.6 464 279.1 464c2.672 0 5.344-.125 8.031-.4062c15.11-1.562 29.09-7.438 40.23-16.44l6.094 5.281c21.84 17.84 54.28 14.56 72.31-7.406l20.27-24.97c19.22 9.906 43.41 5.375 57.41-11.97l9.094-11.25C495.7 392.9 497.6 388.5 499.3 384H624c8.844 0 16-7.156 16-16S632.8 352 624 352z"/></svg>
      </div>
      <div style="text-align: center; margin-top: 2rem;">
        <div :style="`color: ${brand_loading_hex_color}; font-weight: bold; text-transform: uppercase; letter-spacing: 0.2rem; font-family: ${brand_font_family}; font-size: 16px;`">
          LOADING...
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { gsap } from 'gsap';

export default {
  name: "PluginPreloader.vue",

  props: {
    brand: {
      type: Object,
      default: {}
    },
    brand_loading_hex_color: {
      type: String,
      default: "#000000"
    },
  },

  mounted() {
    this.initLoader();
  },

  methods: {
    initLoader() {
      let animation = gsap.timeline({repeat:40})

      function initLoader() {
        gsap.set(".preloader-icon", { autoAlpha:1, scale: 1 })
        animation
          .from(".preloader-icon", { y:0, opacity:0.1, scale: 0.8, stagger:1 })
          .to(".preloader-icon", { y:0, opacity:0.1, scale: 0.8, stagger:1 }, 1)
      }

      initLoader();

      let rmLoaderPromise = new Promise((resolve, reject) => {
        resolve(
          gsap.delayedCall(3, () => {
            gsap.set(".initial-loader", { opacity: 1 });
            gsap.to(".initial-loader", { duration: 0.2, opacity: 0 });
          })
        );
      });

      async function setLoaderOpacityToZero() {
        return await rmLoaderPromise;
      }


      function removeLoaderElement() {
        let el = document.querySelector('.initial-loader');
        el.remove();
      }

      function removeLoader() {
        setLoaderOpacityToZero().then(() => {
          setTimeout(() => {
            gsap.killTweensOf('preloader-icon');
            gsap.killTweensOf('initial-loader');
            removeLoaderElement();
          }, 2000);
        });
      }

      window.addEventListener('load', removeLoader)
    }
  },
  computed: {
      brand_font_family: function() {
          if (this.brand?.id === 1) {
              return 'Messina Sans';
          }
          return 'inherit';
      }
  }
}
</script>

<style scoped>
.initial-loader {
  overflow: hidden;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.three-preloader-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.preloader-icon {
  width: 60px;
  height: 60px;
  opacity: 0.2;
  margin-left: 3rem;
}

.preloader-icon:first-child {
  margin-left: 0;
}
</style>
