<template>
    <div :class="full_wrapper_classes">
      <plugin-preloader :brand="brand" :brand_loading_hex_color="brand_loading_hex_color"></plugin-preloader>
      <Header :brand="brand" :frontend_url="frontend_url"/>

      <!--BANNER--->
      <div
        v-if="only_locator_visible === 'false'"
        :class="[this.brand.id == 9 ? 'lg:py-8' : 'lg:py-16', 'w-full md:py-10 relative ez-banner']"
        :style="[7, 8].indexOf(this.brand.id) > -1 ? 'background-image: url('+top_banner+'); background-repeat: no-repeat; background-size: cover;' : ''"
      >
        <div
          class="
            relative
            flex
            justify-center
            xl:flex-row
            flex-col
            xl:container
            mx-auto
            px-0
            md:px-10
          "
        >
          <div class="xl:w-2/5 w-full">
            <h2
                v-if="this.brand.id !== 9"
              class="
                2xl:text-6xl
                xl:text-5xl
                sm:text-4xl
                text-2xl
                font-bold
                text-center
                md:text-left
                md:mr-4
                text-white
                my-6
                lg:mt-20
                md:block
                hidden
                header-title-ez
              "
            >
              Create the Space of Your Dreams
            </h2>
          </div>
          <QuoteFormSinglePage
            :shownOnModal="false"
            :brand="brand.id"
            :brandObject="brand"
            :brand_info="brand"
            :brand_ga="this.brand_ga"
            :brand-name="this.brandName"
            :domain="this.domain"
            :service_types="this.service_types"
            :home_types="this.home_types"
            :new_buy_plans="this.new_buy_plans"
            :window_amount="this.window_amount"
            :door_amount="this.door_amount"
            :window_importance="this.window_importance"
            :interested_products="this.interested_products"
            :lead_types="this.lead_types"
            :business_types="this.business_types"
            :project_conditions="this.project_conditions"
            :project_types="this.project_types"
            :states="this.states"
            :show_close="false"
            :interested_financing="this.interested_financing"
            :redirect_thankyou="this.redirect_thankyou"
            :countries="this.countries"
            :default_country="this.default_country"
            data_form="unique"
            :frontend_url="this.frontend_url"
            :add_wrapper="true"
            :shoppingFor="this.shoppingFor"
          />
        </div>


        <div v-if="this.brand.id === 4" class="eze-bg-image"  :style="'background-image: url('+url_prepend+'/assets/images/eze/banner.png); background-repeat: no-repeat; background-size: cover; background-position: center center;'"></div>
        <div v-else-if="this.brand.id === 9" class="martin-bg-image transition-all ease-in-out duration-300 bg-no-repeat bg-cover"  :style="'background-image: url('+url_prepend+'/assets/images/martin/banner-1.jpg);'"></div>
      </div>

      <!--BANNER--->

      <dealer-locator
        :brand="this.brand.id"
        :brand-object="this.brand"
        :brandName="this.brandName"
        :brand_ga="this.brand_ga"
        :business_types="this.business_types"
        :client_ip="this.client_ip"
        :countries="this.countries"
        :data_form="this.data_form"
        :default_country="this.default_country"
        :door_amount="this.door_amount"
        :frontend_url="this.frontend_url"
        :full_wrapper_classes="this.full_wrapper_classes"
        :home_types="this.home_types"
        :homes_built_per_year="this.homes_built_per_year"
        :iframe_domain="this.iframe_domain"
        :interested_financing="this.interested_financing"
        :interested_products="this.interested_products"
        :lead_types="this.lead_types"
        :new_buy_plans="this.new_buy_plans"
        :project_conditions="this.project_conditions"
        :project_types="this.project_types"
        :redirect_thankyou="this.redirect_thankyou"
        :service_types="this.service_types"
        :states="this.states"
        :user_agent="this.user_agent"
        :window_amount="this.window_amount"
        :window_importance="this.window_importance"
        :brand_subdomain="brand_subdomain"
        :retailers_enabled="retailers_enabled"
        :single_page_form="true"
      ></dealer-locator>

      <!-- EXCELLENCE THROUGH PARTNERSHIP -->
      <div class="border-t py-12 lg:py-16 w-full" :style="`background-color: ${brandBgColor}`" v-if="[7, 8, 9].indexOf(brand.id) === -1 && only_locator_visible === 'false'"> <!-- https://app.clickup.com/t/1z08r89?comment=632268480 -->
        <div class="max-w-5xl px-6 mx-auto">
          <div>
            <img v-if="brand.id === 4" :src="url_prepend + '/assets/images/eze/ezb_etp_badge.png'" class="m-auto"
                 style="max-height: 150px;" alt=""/>
            <img v-if="brand.id === 7" :src="url_prepend + '/assets/images/eze/anlin_etp_badge.png'" class="m-auto"
                 style="max-height: 150px;" alt=""/>
          </div>
          <h2
            class="
              lg:text-4xl
              text-3xl text-ezeprimary
              font-bold
              text-center
              pt-10
              pb-6
              etp-title
            "
          >
            Excellence Through Partnership
          </h2>
          <p
            class="
              md:max-w-4xl
              lg:text-xl
              md:text-lg
              text-sm
              w-full
              text-center
              m-auto
              text-gray-600
              etp-subtitle
            "
          >
            Each year, we honor exceptional dealers who have showcased the highest
            dedication to serving their communities with products that are built for both the everyday and the once in a lifetime.
          </p>
          <br/>
          <p
            class="lg:text-xl md:text-lg text-sm text-center m-auto text-gray-600 tiers-title"
          >
              This program is divided into three tiers: Diamond, Platinum, and Gold.
          </p>
          <div class="items-center pt-20 pb-7">
            <div class="flex flex-wrap">
              <div class="w-full md:w-1/4">
                  <h3
                      class="
                    md:text-right
                    text-center
                    lg:text-2xl
                    md:text-xl
                    text-lg
                    p-3
                    mb-2
                    cursor-pointer
                    hover:bg-gray-50
                    font-bold
                    text-gray-400
                    etp-dealer-level
                  "
                      v-bind:class="{
                    'bg-gray-50 text-ezeprimary': isActive == 'Diamond',
                  }"
                      @click="selectTab('Diamond')"
                  >
                      Diamond Dealers
                  </h3>
                <h3
                  class="
                    md:text-right
                    text-center
                    lg:text-2xl
                    md:text-xl
                    text-lg
                    py-3
                    pr-3
                    pl-1
                    mb-2
                    cursor-pointer
                    hover:bg-gray-50
                    font-bold
                    text-gray-400
                    etp-dealer-level
                  "
                  v-bind:class="{
                    'bg-gray-50 text-ezeprimary': isActive == 'Platinum',
                  }"
                  @click="selectTab('Platinum')"
                >
                  Platinum Dealers
                </h3>
                  <h3
                      class="
                    md:text-right
                    text-center
                    lg:text-2xl
                    md:text-xl
                    text-lg
                    p-3
                    mb-2
                    cursor-pointer
                    hover:bg-gray-50
                    font-bold
                    text-gray-400
                    etp-dealer-level
                  "
                      v-bind:class="{
                    'bg-gray-50 text-ezeprimary': isActive == 'Gold',
                  }"
                      @click="selectTab('Gold')"
                  >
                      Gold Dealers
                  </h3>
              </div>

              <div
                class="w-full md:w-3/4 flex flex-wrap items-start"
                v-if="isActive === 'Gold'"
              >
                <div
                  v-for="(dealer, index) in gold"
                  :key="index"
                  class="
                    text-gray-600 text-sm
                    w-1/2
                    sm:w-1/3
                    lg:w-1/4
                    p-3
                    text-center
                    etp-dealer-name
                  "
                >
                  {{ dealer.name }}
                </div>
              </div>
              <div
                class="w-full md:w-3/4 flex flex-wrap items-start"
                v-if="isActive === 'Platinum'"
              >
                <div
                  v-for="(dealer, index) in platinum"
                  :key="index"
                  class="
                    text-gray-600 text-sm
                    w-1/2
                    sm:w-1/3
                    lg:w-1/4
                    p-3
                    text-center
                    etp-dealer-name
                  "
                >
                  {{ dealer.name }}
                </div>
              </div>
              <div
                class="w-full md:w-3/4 flex flex-wrap items-start"
                v-if="isActive === 'Diamond'"
              >
                <div
                  v-for="(dealer, index) in diamond"
                  :key="index"
                  class="
                    text-gray-600 text-sm
                    w-1/2
                    sm:w-1/3
                    lg:w-1/4
                    p-3
                    text-center
                    etp-dealer-name
                  "
                >
                  {{ dealer.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--- END ETP -->

      <Footer v-if="displayFooter" :brand="brand" :frontend_url="frontend_url"/>
    </div>
  </template>

  <script>
  import Header from "./FindDealerEzeHeader.vue";
  import Footer from "./FindDealerEzeFooter.vue";

  let VueScrollTo = require("vue-scrollto");
  import GoogleMap from "./GoogleMap";
  import QuoteFormSinglePage from './QuoteFormSinglePage';
  import SvgLocation from './SvgLocation.vue';
  import SvgPhone from './SvgPhone.vue';
  import DealerLocator from "./DealerLocator";

  export default {
    components: {
      Header,
      Footer,
      GoogleMap,
      QuoteFormSinglePage,
      SvgLocation,
      SvgPhone,
      DealerLocator,
    },

    props: {
      service_types: {},
      home_types: {},
      new_buy_plans: {},
      window_amount: {},
      window_importance: {},
      door_amount: {},
      interested_products: {},
      lead_types: {},
      business_types: {},
      project_conditions: {},
      project_types: {},
      coi_dealers: [],
      states: {},
      brand: "",
      brand_info: {},
      brand_ga: "",
      iframe_domain: "",
      interested_financing: "",
      redirect_thankyou: "",
      countries: {},
      default_country: "",
      retailers_enabled: "",
      frontend_url:
        "" /** For images and enpoints. Not needed unless accessing from other domain */,
      client_ip: "",
      user_agent: "",
      append_classes: "",
      brand_subdomain: "",
      homes_built_per_year: "",
      brand_loading_hex_color: {
          type: String,
          default: '#47D7AC'
      },
      only_locator_visible: {
          type: String,
          default: 'false',
      },
    },

    data() {
      return {
        isActive: "Diamond",
        dealers: [],
        shoppingFor: "",
        mapSelector: "https",
        show_results: "none",
        show_results_loading: false,
        show_distance: 0,
        search_status: 0,
        bannerImage: "assets/images/banner1.png",
        data_form: "shared",
        params: {
          zipcode: "",
          distance: 15,
          latitude: 0.0,
          longitude: 0.0,
          type: 1,
          showroom: false,
          primary_segment: ["R"],
          brand: this.brand.id,
          international: false,
        },
        no_results: false,
        distances: this.distanceOptions(),
        has_errors: false,
        domain: process.env.MIX_APP_DOMAIN,
        userLocation: "" /* this.getUserZipCode() */,
        showUseMyLocationButton: false,
        innerWidth: null,
        maxHeight: 900,
        page: 1,
        perPage: 3,
        pages: [],
      };
    },
    mounted() {
      this.selectTab("Diamond");

      if (
        navigator.platform.indexOf("iPhone") != -1 ||
        navigator.platform.indexOf("iPad") != -1
      ) {
        this.mapSelector = "maps";
      }
      this.initGoogleApi();

      this.innerWidth = window.innerWidth;
      window.addEventListener('resize', () => {
        this.innerWidth = window.innerWidth
      })
    },
    methods: {
      selectTab(tab) {
        this.isActive = tab;
      },
      initGoogleApi() {
        if (typeof google !== "undefined" && google.maps && google.maps.places) {
          return;
        }
        let el = document.createElement("script");
        el.src =
          "https://maps.googleapis.com/maps/api/js?key=" +
          process.env.MIX_GMAP_API_KEY +
          "&libraries=places&language=en";
        document.body.appendChild(el);
      },
      hideUseMyLocationButton() {
        setTimeout(() => {
          this.showUseMyLocationButton = false;
        }, 200);
      },
      useMyLocation() {
        this.getUserZipCode();
      },
      sanitizeZipCode() {
        this.params.zipcode = this.params.zipcode.replace(/[A-z]/g, "");
      },
      submitSearch() {
        if (this.show_results === 'loading') return;
        this.dealers = [];
        if (this.search_status === 0) {
          if (this.params.international) {
            this.searchDealers(1);
          } else {
            this.fetchLatLong();
          }
        } else if (this.search_status === 1) {
          this.searchDealers(1);
        }
      },
      getUserZipCode() {
        if (!google) {
          return;
        }
        var _t = this;
        navigator.geolocation.getCurrentPosition(position => {
          var lat = position.coords.latitude;
          var lng = position.coords.longitude;
          // Auto load results for lat and long
          this.params.latitude = lat;
          this.params.longitude = lng;
          this.show_results = 'loading-auto';
          this.searchDealers(1);
          var resultsWrapperElements = this.$el.getElementsByClassName('results-wrapper');
          if (resultsWrapperElements[0]) {
            resultsWrapperElements[0].scrollIntoView({behavior: 'smooth'});
          }

          // Nevertheless, try to load the user zipcode derived from the data we just got
          // and place it in the input, at least for the user's information
          var point = new google.maps.LatLng(lat, lng);
          new google.maps.Geocoder().geocode({
              'latLng': point
            },
            function (res, status) {
              if (status === "OK") {
                // Try to get the zipcode from the first result that allows to do so
                console.log(res);
                for (var i = 0; i < res.length; i++) {
                  var address = res[i].formatted_address;
                  var zip = address.match(/,\s\w{2}\s(\d{5})/);
                  if (zip && zip[1]) {
                    _t.params.zipcode = zip[1];
                    break;
                  }
                }
              }
            })
        });
      },
      fetchLatLong() {
        if (!this.params.zipcode) {
          this.has_errors = true;
          return true;
        }

        this.show_results = "loading";

        window.axios.defaults.headers.common = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };

        var map_url =
          "https://maps.googleapis.com/maps/api/geocode/json?key=" +
          process.env.MIX_GMAP_API_KEY +
          "&components=postal_code:" +
          this.params.zipcode;
        axios.get(map_url).then(
          (response) => {
            if (response.status === 200) {
              if (response.data.results.length > 0) {
                this.no_results = false;
                var location = response.data.results[0].geometry.location;
                this.params.latitude = location.lat;
                this.params.longitude = location.lng;
                this.searchDealers(1);
              } else {
                this.show_results = "none";
                this.no_results = true;
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
      },
      searchDealers(search_type) {
        this.params.type = search_type;
        this.page = 1;

        axios.post(this.url_prepend + "/api/dealer/search", this.params).then(
          (response) => {
            const resultContainer = document.getElementById('result-container');
            resultContainer.scrollIntoView({behavior: "smooth", block: "start"});
            if (response.data.success && response.data.dealers.length) {
              this.no_results = false;
              this.dealers = response.data.dealers;
              this.show_results = "yes";
              this.show_distance = search_type == 1 ? 1 : 0;
            } else {
              this.no_results = true;
              this.show_results = "none";
            }
          },
          (error) => {
            console.log(error);
          }
        );
      },
      distanceOptions() {
        let distance = [
          {id: 15, value: "15 Miles"},
          {id: 20, value: "20 Miles"},
          {id: 25, value: "25 Miles"},
          {id: 30, value: "30 Miles"},
          {id: 50, value: "50 Miles"},
          {id: 100, value: "100 Miles"},
        ];

        return distance;
      },
      showQuotePopup(dealer) {
        let targetModalComponent = this.brand.id === 4 ? EzeBreezeQuoteFormVue : QuoteFormSinglePage;
        this.$modal.show(
          targetModalComponent,
          {
            shownOnModal: true,
            dealer: dealer,
            brand: this.brand.id,
            brandObject: this.brand,
            brand_info: this.brand,
            brand_ga: this.brand_ga,
            domain: this.domain,
            service_types: this.service_types,
            home_types: this.home_types,
            new_buy_plans: this.new_buy_plans,
            window_amount: this.window_amount,
            door_amount: this.door_amount,
            window_importance: this.window_importance,
            interested_products: this.interested_products,
            lead_types: this.lead_types,
            business_types: this.business_types,
            project_conditions: this.project_conditions,
            project_types: this.project_types,
            states: this.states,
            show_close: true,
            interested_financing: this.interested_financing,
            redirect_thankyou: this.redirect_thankyou,
            countries: this.countries,
            default_country: this.default_country,
            data_form: "unique",
            frontend_url: this.frontend_url,
            add_wrapper: true,
            shoppingFor: this.shoppingFor,
            full_wrapper_classes: this.full_wrapper_classes
          },
          {
            height: "auto",
            clickToClose: false,
            name: "quote_modal",
            adaptive: true,
            scrollable: true,
          }
        );

        setTimeout(() => {
          parent.postMessage({task: "scroll_top"}, "*");
          setTimeout(window.pgtAccomodatePopup, 300);
        }, 50);
      },
      showBusinessPopup() {
        this.$modal.show(BusinessContact);
      },
      initApiToken() {
        axios
          .post(this.url_prepend + "/api/token/request", {
            dealer_id: "general",
            secret: "general",
          })
          .then(
            (response) => {
              if (response.data.success) {
                console.log(response.data.message);
              }
            },
            (error) => {
              console.log(error);
            }
          );
      },
      scrollTo(elementId) {
        const element = document.querySelector(elementId);
        element.scrollIntoView({behavior: "smooth", block: "start"});
      },
      setPages() {
        this.pages = [];
        let numberOfPages = Math.ceil(this.dealers.length / this.perPage);
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },
      paginate(dealers) {
        let page = this.page;
        let perPage = this.perPage;
        let from = (page * perPage) - perPage;
        let to = (page * perPage);
        return dealers.slice(from, to);
      },
    },
    computed: {
      top_banner() {
          let out = this.url_prepend;
          if (this.brand.id === 4) {
              return out + '/assets/images/eze/banner.png';
          }
          if (this.brand.id === 7) {
              return out + '/assets/images/anlin/banner.jpg';
          }
          if (this.brand.id === 8) {
              return out + '/assets/images/wws/banner.png';
          }
          if (this.brand.id === 9) {
              return out + '/assets/images/martin/banner-2.jpg';
          }
          return '';
      },
      displayedDealers() {
        return this.paginate(this.dealers);
      },
      platinum() {
        return _.filter(this.coi_dealers_hardcoded, {level: 3});
      },
      diamond() {
        return _.filter(this.coi_dealers_hardcoded, {level: 2});
      },
      gold() {
        return _.filter(this.coi_dealers_hardcoded, {level: 1});
      },
      url_prepend() {
        return this.frontend_url ? this.frontend_url : "";
      },
      brand_subdomain_prefix() {
        return this.brand_subdomain ? this.brand_subdomain : "";
      },
      brandName() {
        if (this.brand.id === 4) return 'EZE';
        if (this.brand.id === 7) return 'Anlin';
        if (this.brand.id === 8) return 'Western Window Systems';
        if (this.brand.id === 9) return 'Martin Doors';
      },
      full_wrapper_classes() {
        let res = 'font-opensans wtb-wrapper';
        if (this.append_classes) {
          res += ' ' + this.append_classes;
        }
        return res;
      },
      displayFooter() {
        return false;
      },
      brandBgColor() {
        if (this.brand.id === 4) {
          return '#FFFFFF';
        }
        return '';
      },
      coi_dealers_hardcoded() {
          return [
               // GOLD
               { name: "FLORIDA WOOD WINDOW & DOOR", level: 1 },
                  { name: "SHUTTER UP INDUSTRIES", level: 1 },
                  { name: "SOLAR-TITE, INC", level: 1 },
                  { name: "VERO GLASS & MIRROR", level: 1 },
                  { name: "CLEARWATER WINDOW & DOOR", level: 1 },
                  { name: "PREMIER GLASS & SCREEN, INC", level: 1 },
                  { name: "MANNING BUILDING SUPPLIES", level: 1 },
                  { name: "JVR IMPACT WINDOWS & DOORS", level: 1 },
                  { name: "FLORIDA SOLAR & AIR", level: 1 },
                  { name: "TRENDEX CORPORATION", level: 1 },
                  { name: "O'DONNELL IMPACT WINDOWS & STORM PROTECTION", level: 1 },
                  { name: "SARASOTA GLASS & MIRROR, INC", level: 1 },
                  { name: "DALY ALUMINUM", level: 1 },
                  { name: "BUDD SEVERINO ADV HOME EXT", level: 1 },
                  { name: "SOUTH FLORIDA WINDOWS & DOORS", level: 1 },
                  { name: "W & W LUMBER COMPANY", level: 1 },
                  { name: "WEATHERMASTERS", level: 1 },
                  { name: "GRAYHAWK SYSTEMS, LLC", level: 1 },
                  { name: "EAST COAST LUMBER & SUPPLY CO", level: 1 },
                  { name: "TMD WINDOWS & DOORS", level: 1 },
                  { name: "BAHAMA GLASS & WINDOW  INC", level: 1 },
                  { name: "NAPLES GLASS & ALUMINUM INC", level: 1 },

                  // DIAMOND
                  {name: "WEATHER TITE WINDOWS", level: 2},
                  {name: "GLASS PROFESSIONALS", level: 2},
                  {name: "RAYMOND BUILDING SUPPLY LLC", level: 2},
                  {name: "FLORIDA WINDOWS & DOORS", level: 2},
                  {name: "SOUTHERN EXPOSURE SUNROOMS", level: 2},
                  {name: "ALL GLASS", level: 2},
                  {name: "DIRECT WINDOW & DOOR", level: 2},
                  {name: "RICE WINDOWS & DOORS", level: 2},
                  {name: "HBS, INC", level: 2},
                  {name: "R Q BUILDING PRODUCTS, INC", level: 2},
                  {name: "SUNCOAST CONTRACTORS SUPPLY", level: 2},
                  {name: "CARROLLWOOD WINDOW & DOOR, INC", level: 2},
                  {name: "PRESTIGE WINDOWS & DOORS", level: 2},
                  {name: "DIAMOND GLASS & ALUMINUM, LLC", level: 2},

                  // PLATINUM

                  { name: "SUN WINDOW & DOOR", level: 3 },
                  { name: "DOVI WINDOW DESIGNS, INC", level: 3 },
                  { name: "ALLIED BUILDING PRODUCTS", level: 3 },
                  { name: "CROW INDUSTRIES", level: 3 },
                  { name: "DIAMOND GLASS & ALUMINUM, LLC", level: 3 },
                  { name: "M AND W WINDOWS, LLC", level: 3 },
                  { name: "BAY GLASS & WINDOW  INC", level: 3 },
                  { name: "TRIPLE DIAMOND GLASS PRODUCTS", level: 3 },
                  { name: "ACTIVE DOOR & WINDOW CO INC", level: 3 },
                  { name: "E & D QUALITY INSTALL, INC", level: 3 },
                  { name: "ENGLEWOOD GLASS & MIRROR & AUTO", level: 3 },
                  { name: "GUARDIAN HURRICANE PROTECTION", level: 3 },
                  { name: "KIMAL LUMBER COMPANY", level: 3 },
                  { name: "STEVE'S WINDOWS AND DOORS", level: 3 },
                  { name: "WEST COAST WINDOW & DOOR, INC", level: 3 },
                  { name: "LOUMAC DISTRIBUTORS-US LBM LLC", level: 3 },
                  //{ name: "WEATHER TITE WINDOWS", level: 3 },
                  //{ name: "WEATHERWALL ENCLOSURES SYSTEMS", level: 3 },
          ];
      }
    },

    watch: {
      dealers(newValue, oldValue) {
        if (this.maxHeight < 1024) {
          this.setPages();
        }

        let vm = this;
        this.$nextTick(() => {
          if (newValue.length > 5 && vm.innerWidth >= 1024) {
            let nodes = document.querySelectorAll("div[data-name='desktopDealer']");
            let iterations = 0;
            let dealersToShow = 5;
            let maxHeight = 0;
            nodes.forEach(function (node) {
              if (iterations === dealersToShow) return;
              iterations += 1;

              maxHeight += node.clientHeight;
            })

            vm.maxHeight = maxHeight;
          }
        })
      }
    },
  };
  </script>

  <style lang="scss">
  .block-enter {
    opacity: 0;
  }

  .block-enter-active {
    animation: slide-in 0.5s ease-out backwards;
    transition: opacity 1s;
  }

  .block-leave {
    opacity: 0;
  }

  .block-leave-active {
    animation: slide-out 0s ease-out forwards;
    transition: opacity 0s;
    opacity: 0;
  }

  @keyframes slide-in {
    from {
      transform: translateY(20px);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes slide-out {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-20px);
    }
  }

  .eze-bg-image {
    width: 100%;
    height: 1750px;
    position: absolute;
    top: 0;
    margin: auto;
    z-index: -1000;
    @media only screen and (max-width: 767px) {
      height: 3000px;
    }
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      /* For tablets and up: */
      height: 2130px;
    }
    @media only screen and (min-width: 1024px) and (max-width: 1439px) {
      /* For tablets and up: */
      height: 1960px;
    }
  }

  .martin-bg-image {
    width: 100%;
    height: 1550px;
    position: absolute;
    top: 0;
    margin: auto;
    z-index: -1000;
    @media only screen and (max-width: 767px) {
      height: 3000px;
    }
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      /* For tablets and up: */
      height: 2130px;
    }
    @media only screen and (min-width: 1024px) and (max-width: 1438px) {
      /* For tablets and up: */
      background-position: left -170px !important;
      height: 1960px;
    }

    @media only screen and (min-width: 1439px) {
      /* For tablets and up: */
      background-position: 15% 50% !important;
      height: 100%;
    }
  }



  </style>
