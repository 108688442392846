<template>
    <div :class="['p-6 w-full md:px-6 md:pt-10 md:pb-6 ez-form-wrapper', full_wrapper_classes]"
         :style="'background-color: rgba('+brand_rgba_tuple+', 1); margin-left: 0 !important; margin-top: 0 !important;'">
        <loading
            :active.sync="isLoading"
            :can-cancel="false"
            :is-full-page="false"
        ></loading>
        <button id="closeModal" @click.prevent="$modal.hide('confirm-address-eze')">
            x
        </button>
        <div class="form-container-ez">
            <h2 class="mb-2 md:mb-1.5" :data-forceStyle="`title_${brandName}`">We found a suggested address.</h2>
            <div :class="[{'opacity-0': isLoading}, 'w-full text-white']">
                <div class="mb-2" v-show="googleProvidedAddressFormatted">
                    <label class="inline-flex items-baseline cursor-pointer">
                        <input
                            id="googleSuggestion"
                            :checked="address_source === 1"
                            autocomplete="off"
                            class="eze-form-radio"
                            name="panels"
                            type="radio"
                            value="1"
                            @click="address_source = 1"
                        />
                        <span class="ml-1 form-answer-ez" style="font-size: revert;">Use suggested address: {{
                                googleProvidedAddressFormatted
                            }}</span>
                    </label>
                </div>

                <div
                    class="mb-2"
                >
                    <label class="inline-flex items-baseline cursor-pointer">
                        <input
                            id="googleSuggestion"
                            :checked="address_source === 2"
                            autocomplete="off"
                            class="eze-form-radio"
                            name="panels"
                            type="radio"
                            value="2"
                            @click="select(2)"
                        />
                        <span class="ml-1 form-answer-ez" style="font-size: revert;">Use address I entered:  {{
                                userInputAddressFormatted
                            }}</span>
                    </label>
                </div>

                <button
	                :data-forceStyle="`confirm_submit_button_${brandName}`"
                  class="
										rounded
										text-white text-sm
										block
										h-10
										mt-2
										leading-10
										text-center
										font-bold
										bg-ezesecondary-important
										focus:outline-none
										focus:shadow-outline-eze
										get-quote-button-ez
									"
                    @click.prevent="submitSelection()"
                >
                    Submit
                </button>
            </div>


        </div>
    </div>
</template>

<script>
import Loading from "vue-loading-overlay";

export default {
    name: "ConfirmAddressFormEzeAndAnlin",

    components: {
        Loading,
    },

    props: {
        full_wrapper_classes: {
            type: String,
            default: '',
        },
        quote_request: {
            type: Object,
            default: null,
        },
        brand_rgba_tuple: String,
        url_prepend: {
            type: String,
            required: true,
        }
    },

    data() {
        return {
            isLoading: true,

            options: [
                {id: 1, name: 'google-maps-address'},
                {id: 2, name: 'user-input-address'},
            ],

            address_source: 1,

            addressPickedViaGmapDropdown: false,

            googleProvidedAddress: null,
            googleProvidedAddressFormatted: null,

            userInputAddressFormatted: null,

            addressConfirmed: false,
            addressNotFound: false,
            pickedGmapAddressViaPopup: false,
            gmapSuggestedAddress: {
                address1: null,
                city: null,
                state: null,
                zip: null,
                country: null,
                county: null,
                latitude: null,
                longitude: null,
            },
        }
    },

    mounted() {
        let country = this.quote_request.country === 'United States' ? 'USA' : this.quote_request.country;
        this.userInputAddressFormatted = `${this.quote_request.address1} ${this.quote_request.city} ${this.quote_request.state} ${this.quote_request.zip} ${country}`;
        this.validateAddressViaGoogle();
    },

    methods: {
        async validateAddressViaGoogle() {
            this.addressPickedViaGmapDropdown = false;
            this.addressConfirmed = false;

            await axios
                .post(this.url_prepend + '/api/gmaps-proxy', {
                    url: 'https://maps.googleapis.com/maps/api/geocode/json?address=' + encodeURI(this.userInputAddressFormatted)
                })
                .then(r => {
                    if (r && r.data && r.data.results && r.data.results[0]) {
                        let result = r.data.results[0];
                        this.googleProvidedAddress = result;
                        this.googleProvidedAddressFormatted = result.formatted_address;
                        this.addressNotFound = false;
                    } else {
                        this.googleProvidedAddress = null;
                        this.googleProvidedAddressFormatted = null;
                        this.addressNotFound = true;
                    }
                })
                .catch(e => {
                    this.googleProvidedAddress = null;
                    this.googleProvidedAddressFormatted = null;
                    this.addressNotFound = true;
                    throw e
                })
                .finally(() => {
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 500)
                })
        },
        select(id) {
            this.address_source = id;
        },
        submitSelection() {
            let gmapSuggestionSelected = this.address_source === 1;
            if (this.googleProvidedAddress && gmapSuggestionSelected) {
                let streetName = this.googleProvidedAddress.address_components.find(e => e.types.indexOf('route') > -1);
                if (streetName) streetName = streetName.long_name;
                let streetNumber = this.googleProvidedAddress.address_components.find(e => e.types.indexOf('street_number') > -1);
                if (streetNumber) streetNumber = streetNumber.long_name;
                let address1 = null;
                if (streetName && streetNumber) {
                    this.gmapSuggestedAddress.address1 = streetNumber + ' ' + streetName;
                }
                let city = this.googleProvidedAddress.address_components.find(e => e.types.indexOf('locality') > -1);
                if (city) this.gmapSuggestedAddress.city = city.long_name;
                let state = this.googleProvidedAddress.address_components.find(e => e.types.indexOf('administrative_area_level_1') > -1);
                if (state) this.gmapSuggestedAddress.state = state.long_name;
                let zip = this.googleProvidedAddress.address_components.find(e => e.types.indexOf('postal_code') > -1);
                if (zip) this.gmapSuggestedAddress.zip = zip.long_name;
                let country = this.googleProvidedAddress.address_components.find(e => e.types.indexOf('country') > -1);
                if (country) this.gmapSuggestedAddress.country = country.long_name;
                let county = this.googleProvidedAddress.address_components.find(e => e.types.indexOf('administrative_area_level_2') > -1);
                if (county) this.gmapSuggestedAddress.county = county.long_name;

                this.gmapSuggestedAddress.latitude = this.googleProvidedAddress.geometry?.location?.lat;
                this.gmapSuggestedAddress.longitude = this.googleProvidedAddress.geometry?.location?.lng;
            }


            this.googleProvidedAddress = null;
            this.addressNotFound = false;
            this.$emit('address-confirmed', {
                gmapSuggestionSelected: gmapSuggestionSelected,
                gmapSuggestedAddress: this.gmapSuggestedAddress,
            });
        },
    },
		
		computed: {
        brandName() {
            return 'MARTIN';
				},
		}
}
</script>

<style lang="scss" scoped>
#closeModal {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #ffffff;
    background-color: transparent;
    border: 0;
}

[data-forceStyle="title_EZE"] {
  font-family: 'NeutrafaceText', sans-serif; text-transform: uppercase; font-size: 30px;
}

[data-forceStyle="title_MARTIN"] {
  font-family: 'Open Sans', sans-serif; text-transform: none; font-size: 30px;
}

[data-forceStyle="confirm_submit_button_EZE"] {

}

[data-forceStyle="confirm_submit_button_MARTIN"] {
  padding-left: 1rem;
  padding-right: 1rem;
}
</style>
