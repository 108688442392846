export default {
    computed: {
        brandLongName() {
            switch (parseInt(this.brand)) {
                case 1:
                    return 'PGT Custom Windows and Doors';
                case 2:
                    return 'CGI Impact Resistant Windows & Doors';
                case 3:
                    return 'WinDoor';
                case 4:
                    return 'EzeBreeze'
                case 6:
                    return 'Eco Window Systems';
            }
            return this.brandName;
        }
    }
}
