<template>
  <div data-name="ez-breeze-quote-form" :class="shownOnModal
    ? 'w-full md:px-6 md:py-10 ez-form-wrapper wtb-wrapper ' + full_wrapper_classes
    : 'xl:max-w-5xl w-full relative lg:px-12 md:px-10 md:py-8 ez-form-wrapper ' + full_wrapper_classes
    " class="p-6" :style="shownOnModal
    ? 'background-color: rgba(' + brandRgbaTuple + ', 1)'
    : 'background-color: rgba(' + brandRgbaTuple + ', 0.9)'
    ">
    <div class="form-container-ez font-mont">
      <!-- Loader -->
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage"></loading>
      <!-- End Loader -->
      <button v-if="show_close" @click="$modal.hide('quote_modal')"
        class="absolute text-white top-0 right-0 p-4 bg-transparent border-none">
        &times;
      </button>
      <p class="header_text mt-2 lg:mt-0 leading-none lg:leading-tight">
        Adding beauty and value to your home with new Anlin windows and doors is just a few clicks away. Please fill out this form to get started with a quote.
      </p>
      <!-- Questions -->
      <div class="eze-form-questions-step-1 grid grid-cols-1 lg:grid-cols-3 grid-flow-row justify-items-stretch one-row-question-options lg:mt-6">
        <!-- New Or Existing Project-->
        <h4 class="mb-2 md:mb-1.5 question-label col-span-3">What type of project is this?</h4>
        <div class="mb-2 md:mb-0 col-span-3 lg:col-span-1" v-for="(answer, index) in service_types" :key="index">
          <label class="inline-flex items-baseline answer-label">
            <input autocomplete="off" type="radio" name="serviceType" class="eze-form-radio"
              :id="`serviceType_${answer.name}`" :value="answer.id" v-model="quote_request.serviceType" />
            <span class="ml-1 form-answer-ez">{{ answer.name }}</span>
          </label>
        </div>
      </div>
      <div :class="{ 'text-xs font-medium text-red-500 form-error-ez': errors.serviceType != '' }">
        {{ errors.serviceType }}
      </div>
      <div class="eze-form-questions-step-1 grid grid-cols-1 lg:grid-cols-2 lg:gap-4 grid-flow-row justify-items-stretch lg:mt-6">
        <!-- Type of the home -->
        <div class="text-sm text-white col-span-3 lg:col-span-1">
          <h4 class="mb-2 md:mb-1.5 question-label">What type of home do you have?</h4>
          <div :class="{ 'text-xs font-medium text-red-500 form-error-ez': errors.homeType != '' }">
            {{ errors.homeType }}
          </div>
          <div class="mb-2 md:mb-0" v-for="(answer, index) in home_types" :key="index">
            <label class="inline-flex items-baseline answer-label" >
              <input autocomplete="off" type="radio" name="homeType" class="eze-form-radio"
                :id="`homeType_${answer.name}`" :value="answer.id" v-model="quote_request.homeType"/>
              <span class="ml-1 form-answer-ez">{{ answer.name }}</span>
            </label>
          </div>
        </div>
        <!-- Need installation services? -->
        <div class="text-sm text-white col-span-3 lg:col-span-1">
          <h4 class="mb-2 md:mb-1.5 question-label">Do you need installation services for this project?</h4>
          <div :class="{ 'text-xs font-medium text-red-500 form-error-ez': errors.interested_installation != '' }">
            {{ this.errors.interested_installation }}
          </div>
          <div class="mb-2 md:mb-0" v-for="(question, index) in questions.projectInstallation" :key="index">
            <label class="inline-flex items-baseline answer-label">
              <input autocomplete="off" type="radio" name="inst_service"
                :id="`interested_installation_${question.answer}`" class="eze-form-radio" :value="question.value"
                v-model="quote_request.interested_installation" />
              <span class="ml-1 form-answer-ez">{{ question.answer }}</span>
            </label>
          </div>
        </div>
      </div>
      <div class="eze-form-questions-step-1 grid grid-cols-3 lg:gap-4 grid-flow-row justify-items-stretch lg:mt-6">
        <!-- Interested products -->
        <div class="text-sm text-white col-span-3 lg:col-span-1">
          <h4 class="mb-2 md:mb-1.5 question-label">Which products are you interested in buying?</h4>
          <div :class="{ 'text-xs font-medium text-red-500 form-error-ez': errors.interestedProducts != '' }">
            {{ this.errors.interestedProducts }}
          </div>
          <div class="mb-2 md:mb-0" v-for="(answer, index) in interested_products" :key="index">
            <label class="inline-flex items-baseline answer-label" v-on:click="homeTypeSelected">
              <input autocomplete="off" type="checkbox" name="interestedProducts" class="eze-form-small-checkbox"
                :id="`productsOfInterest_${answer.name}`" :value="answer.id" v-model="quote_request.interestedProducts"/>
              <span class="ml-1 form-answer-ez">{{ answer.name }}</span>
            </label>
          </div>
        </div>
        <!-- Number of windows required -->
        <div class="text-sm text-white col-span-3 lg:col-span-1 disable-content" :id="shownOnModal
          ? 'window-count-container-on-modal' : 'window-count-container'">
          <h4 class="mb-2 md:mb-1.5 question-label">How many windows do you need for this project?</h4>
          <div :class="{ 'text-xs font-medium text-red-500 form-error-ez': errors.windowAmount != '' }">
            {{ errors.windowAmount }}
          </div>
          <div class="mb-2 md:mb-0" v-for="(answer, index) in window_amount" :key="index">
            <label class="inline-flex items-baseline answer-label">
              <input autocomplete="off" type="radio" name="windowAmount" class="eze-form-radio"
                :id="`windowAmount_${answer.name}`" :value="answer.id" v-model="quote_request.windowAmount" />
              <span class="ml-1 form-answer-ez">{{ answer.name }}</span>
            </label>
          </div>
        </div>
        <!-- Number of doors required -->
        <div class="text-sm text-white col-span-3 lg:col-span-1 disable-content" :id="shownOnModal
          ? 'door-count-container-on-modal' : 'door-count-container'">
          <h4 class="mb-2 md:mb-1.5 question-label">How many doors do you need for this project?</h4>
          <div :class="{ 'text-xs font-medium text-red-500 form-error-ez': errors.doorAmount != '' }">
            {{ errors.doorAmount }}
          </div>
          <div class="mb-2 md:mb-0" v-for="(answer, index) in door_amount" :key="index">
            <label class="inline-flex items-baseline answer-label">
              <input autocomplete="off" type="radio" name="doorAmount" class="eze-form-radio"
                :id="`doorAmount_${answer.name}`" :value="answer.id" v-model="quote_request.doorAmount" />
              <span class="ml-1 form-answer-ez">{{ answer.name }}</span>
            </label>
          </div>
        </div>
        <!-- Frame options -->
        <div class="text-sm text-white col-span-3 lg:col-span-1">
          <h4 class="mb-2 md:mb-1.5 question-label">Do you have a preference for frames?</h4>
          <div :class="{ 'text-xs font-medium text-red-500 form-error-ez': errors.framePreference != '' }">
            {{ this.errors.framePreference }}
          </div>
          <div class="mb-2 md:mb-0" v-for="(answer, index) in frameOptions" :key="index">
            <label class="inline-flex items-baseline answer-label">
              <input autocomplete="off" type="radio" name="framePreference" class="eze-form-radio"
                :id="`framePreference_${answer.name}`" :value="answer.id" v-model="quote_request.framePreference" />
              <span class="ml-1 form-answer-ez">{{ answer.name }}</span>
            </label>
          </div>
        </div>
        <!-- Frame options -->
        <div class="text-sm text-white col-span-3 lg:col-span-1">
          <h4 class="mb-2 md:mb-1.5 question-label">Which product benefits interest you most?</h4>
          <div :class="{ 'text-xs font-medium text-red-500 form-error-ez': errors.windowImportance != '' }">
            {{ this.errors.windowImportance }}
          </div>
          <div class="mb-2 md:mb-0" v-for="(answer, index) in window_importance" :key="index">
            <label class="inline-flex items-baseline answer-label">
              <input autocomplete="off" type="checkbox" name="windowImportance" class="eze-form-small-checkbox"
                :id="`windowImportance_${answer.name}`" :value="answer.id" v-model="quote_request.windowImportance" />
              <span class="ml-1 form-answer-ez">{{ answer.name }}</span>
            </label>
          </div>
        </div>
        <!-- When are you planning to start -->
        <div class="text-sm text-white col-span-3 lg:col-span-1">
          <h4 class="mb-2 md:mb-1.5 question-label">When are you planning to buy new windows and/or doors?</h4>
          <div :class="{ 'text-xs font-medium text-red-500 form-error-ez': errors.newBuyPlan != '' }">
            {{ this.errors.newBuyPlan }}
          </div>
          <div class="mb-2 md:mb-0" v-for="(answer, index) in new_buy_plans" :key="index">
            <label class="inline-flex items-baseline answer-label">
              <input autocomplete="off" type="radio" name="planningToStart" :id="`planningToStart_${answer.name}`"
                class="eze-form-radio" :value="answer.id" v-model="quote_request.newBuyPlan" />
              <span class="ml-1 form-answer-ez">{{ answer.name }}</span>
            </label>
          </div>
        </div>
      </div>
      <div id="scrollToStepTwo" data-name="interestedInFinance" class="eze-form-questions-step-1 grid grid-cols-1 lg:grid-cols-2 grid-flow-row justify-items-stretch one-row-question-options lg:mt-6">
        <!-- Are you interested in finance -->
        <h4 class="mb-2 md:mb-1.5 question-label col-span-2">Are you interested in special financing that allows you
          to pay for this project over time?</h4>
        <div class="grid grid-cols-1 lg:grid-cols-2 lg:gap-4 grid-flow-row justify-items-stretch">
          <div class="mb-2 md:mb-0" v-for="(question, index) in questions.specialFinancing" :key="index">
            <label class="inline-flex items-baseline answer-label"><input autocomplete="off" type="radio" name="special_financing"
                :id="`specialFinancing_${question.answer}`" class="eze-form-radio"
                :value="question.value"
                v-model="quote_request.interested_financing" />
              <span class="ml-1 form-answer-ez">{{ question.answer }}</span></label>
          </div>
        </div>
        <div :class="{ 'text-xs font-medium text-red-500 form-error-ez': errors.interested_financing != '' }">
          {{ this.errors.interested_financing }}
        </div>
      </div>
      <!-- End Questions -->
      <!-- Step 2 -->
      <div id="stepTwo" class="eze-quote-request-form mt-6" v-show="showStepTwo">
        <!-- First name and last name -->
        <div class="flex justify-between sm:flex-row flex-col md:mb-4">
          <div class="w-full mr-6 mb-3 md:mb-0 ez-input-group">
            <input v-model="quote_request.firstName" type="text" name="firstName" id="firstName" class="
                              w-full
                              md:mb-0
                              md:h-10
                              h-8
                              pl-4
                              focus:outline-none
                              focus:shadow-outline-eze
                              border border-ezebreeze
                              ez-text-input
                          " placeholder="First Name" />
            <span :class="{
              'text-xs font-medium text-red-500': errors.firstName !== '',
            }">
              {{ this.errors.firstName }}
            </span>
          </div>
          <div class="w-full mb-3 md:mb-0 ez-input-group">
            <input v-model="quote_request.lastName" type="text" name="lastName" id="lastName" class="
                              w-full
                              md:mb-0
                              md:h-10
                              h-8
                              pl-4
                              focus:outline-none
                              focus:shadow-outline-eze
                              border border-ezebreeze
                              ez-text-input
                          " placeholder="Last Name" />
            <span :class="{
              'text-xs font-medium text-red-500': errors.lastName !== '',
            }">
              {{ this.errors.lastName }}
            </span>
          </div>
        </div>
        <!-- Email and phone -->
        <div class="flex justify-between sm:flex-row flex-col md:mb-4">
          <div class="w-full mr-6 mb-3 md:mb-0 ez-input-group">
            <input type="email" name="email" id="email" class="
                              w-full
                              md:mb-0
                              md:h-10
                              h-8
                              pl-4
                              focus:outline-none
                              focus:shadow-outline-eze
                              border border-ezebreeze
                              ez-text-input
                          " placeholder="Email" v-model="quote_request.email" />
            <span :class="{ 'text-xs font-medium text-red-500': errors.email !== '' }">
              {{ this.errors.email }}
            </span>
          </div>
          <div class="w-full mb-3 md:mb-0 ez-input-group">
            <input type="text" name="" id="" class="
                              w-full
                              md:h-10
                              h-8
                              pl-4
                              focus:outline-none
                              focus:shadow-outline-eze
                              border border-ezebreeze
                              ez-text-input
                          " placeholder="Phone Number" v-model="quote_request.phone" @input="sanitizePhoneNumber" />
            <span :class="{ 'text-xs font-medium text-red-500': errors.phone !== '' }">
              {{ this.errors.phone }}
            </span>
          </div>
        </div>
        <!-- Address and city -->
        <div class="flex justify-between sm:flex-row flex-col md:mb-4">
          <div class="w-full mr-6 mb-3 md:mb-0 ez-input-group">
            <input ref="inputAddress" type="text" class="
                  w-full
                  md:h-10
                  h-8
                  pl-4
                  focus:outline-none
                  focus:shadow-outline-eze
                  border border-ezebreeze
                  input-address
                  ez-text-input
                " placeholder="Address" v-model="quote_request.address1"
              @change="shouldConfirmAddress = true; addressConfirmed = false;" />
            <span :class="{
              'text-xs font-medium text-red-500': errors.address1 !== '',
            }">
              {{ this.errors.address1 }}
            </span>
          </div>
          <div class="w-full mb-4 md:mb-0 ez-input-group">
            <input type="text" class="
                  w-full
                  md:h-10
                  h-8
                  pl-4
                  focus:outline-none
                  focus:shadow-outline-eze
                  border border-ezebreeze
                  ez-text-input
                " placeholder="City" v-model="quote_request.city" />
            <span :class="{ 'text-xs font-medium text-red-500': errors.city != '' }">
              {{ this.errors.city }}
            </span>
          </div>
        </div>
        <!-- State and zip -->
        <div class="flex justify-between sm:flex-row flex-col md:mb-4">
          <div class="w-full mr-6 mb-3 md:mb-0 ez-input-group">
            <select id="select-state" type="text" class="
                  w-full
                  md:h-10
                  h-8
                  pl-4
                  focus:outline-none
                  focus:shadow-outline-eze
                  border
                  ez-select-input
                          " v-model="quote_request.state" required>
              <option value="" selected>State</option>
              <option v-for="(item, key) in this.states" :key="key" :value="item.name">
                {{ item.name }}
              </option>
            </select>
            <span :class="{
              'text-xs font-medium text-red-500': errors.state !== '',
            }">
              {{ this.errors.state }}
            </span>
          </div>
          <div class="w-full mb-3 md:mb-0 ez-input-group">
            <input type="text" class="
                              w-full
                              md:h-10
                              h-8
                              pl-4
                              focus:outline-none
                              focus:shadow-outline-eze
                              border border-ezebreeze
                              ez-text-input
                          " placeholder="Zip" maxlength="5" v-model="quote_request.zip" @input="sanitizeZipCode" />
            <span :class="{ 'text-xs font-medium text-red-500': errors.zip != '' }">
              {{ this.errors.zip }}
            </span>
          </div>
        </div>
        <!-- Additional details -->
        <div data-name="additionalDetails" class="my-6">
          <div class="text-sm ez-input-group">
            <div class="w-full mr-6 mb-3 md:mb-0">
              <input v-model="quote_request.additional" type="text" max="255" name="additional" class="
                    w-full
                    md:mb-0
                    md:h-10
                    h-8
                    pl-4
                    pr-4
                    focus:outline-none
                    focus:shadow-outline-eze
                    border border-ezebreeze
                    additionalDetails
                    ez-text-input
                  " placeholder="Additional details" />
            </div>
            <span :class="{ 'text-xs font-medium text-red-500': errors.additional !== '', }">
              {{ this.errors.additional }}
            </span>
          </div>
        </div>
      </div>

      <!-- Get quote button -->
      <div v-show="showStepTwo">
        <span v-if="showLegal" class="l-disclaimer pb-3 pb-md-2">Providing your phone number is consent to receive calls,
          texts, and recorded messages from {{ brandLongName }} and its partners.</span>
        <button class="
                      w-full
                      rounded
                      text-white text-sm
                      block
                      h-10
                      leading-10
                      text-center
                      font-bold
                      bg-ezesecondary-important
                      focus:outline-none
                      focus:shadow-outline-eze
                      get-quote-button-ez quotesubmit
                  " @click.prevent="postLead()">
          Submit
        </button>
        <div v-if="showLegal" class="l-disclaimer pt-3 pt-md-2" :data-forcestyle="`ldisclaimer_${brandName}`">
          By providing my phone number and clicking on the “Submit” button above, I consent, acknowledge, and agree to the
          following:
          <ul>
            <li>{{ brandLongName }}’s <a :href="brandPrivacyPolicy" target="_blank">Privacy Policy</a>.</li>
            <li>That I am providing my express written consent to share my information with {{ brandLongName }}’s
              <a v-if="brandAuthorizedThirdPartiesUrl" target="_blank"
                :href="brandAuthorizedThirdPartiesUrl">partners,</a>
              <span v-else>partners,</span>
              and authorized third parties calling on their behalf, to deliver calls or text messages to me, including for
              marketing purposes, using an automatic telephone dialing system or an artificial or prerecorded voice to the
              phone number I have provided. I understand that I am providing this consent to receive such telephone calls
              even if my telephone number is currently listed on any federal, state, local, internal, or corporate
              Do-Not-Call (“DNC”) Lists.
            </li>
            <li>I understand that I do not have to agree to receive these types of calls or text messages as a condition
              of purchasing any goods or services. I understand that instead of providing consent, I may call {{
                brandContactPhone }} to speak with an individual customer care representative.</li>
          </ul>
        </div>
      </div>
    </div>

    <!--    <modal name="confirm-address-eze" :clickToClose="false" height="auto" :adaptive="true" width="unset" max-width="800px" classes="w-full xl:w-1/2 relative mx-auto rounded-br-15 top-0">-->
    <modal name="confirm-address-eze" :clickToClose="false" height="auto" :adaptive="true" classes="modal-override">
      <ConfirmAddressFormEze :brand_rgba_tuple="brandRgbaTuple" :full_wrapper_classes="full_wrapper_classes"
        :quote_request="this.quote_request" v-on:address-confirmed="handleAddressConfirmed" :url_prepend="url_prepend">
      </ConfirmAddressFormEze>
    </modal>
  </div>
</template>

<script>

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import $ from 'jquery';
import brandLongName from "../mixins/brandLongName";
import brandLinks from "../mixins/brandLinks";
import ConfirmAddressFormEze from "./ConfirmAddressFormEze.vue";

let gmapAutocompleteInitialized = false;

export default {
  name: "QuoteFormSinglePage",
  mixins: [brandLongName, brandLinks],
  props: {
    shownOnModal: {
      type: Boolean,
      default: false,
    },
    dealer: "",
    service_types: {},
    home_types: {},
    new_buy_plans: {},
    window_amount: {},
    door_amount: {},
    window_importance: {},
    interested_products: {},
    lead_types: {},
    business_types: {},
    project_conditions: {},
    project_types: {},
    states: {},
    countries: {},
    default_country: "",
    brand: "",
    brandObject: Object,
    source_id: "",
    interested_financing: "",
    redirect_thankyou: "",
    brand_ga: "",
    iframe_domain: "",
    show_close: false,
    brandName: {
      type: String,
      default: ''
    },
    data_form: "",
    add_wrapper: false,
    full_wrapper_classes: {
      type: String,
      default: ''
    },
    frontend_url: "",
    client_ip: "",
    user_agent: "",
  },
  data() {
    return this.initData();
  },
  created() {
    // if (this.dealer) {
    // this.initApiToken();
    // }
  },
  mounted() {
    if (this.shownOnModal) {
      // Append dropdown when scrolling to input address element
      let inputAddress = this.$refs.inputAddress;
      $('.v--modal-overlay').scroll(() => {
        let newTop = $(inputAddress).offset().top + $(inputAddress).outerHeight();
        $('.pac-container').css('top', newTop + 'px');
      })
    }
  },
  components: {
    Loading,
    ConfirmAddressFormEze,
  },

  watch: {
    showStepTwo(newValue, oldValue) {
      if (!this.scrolledToStepTwo && newValue === true && !this.shownOnModal) {
        this.scrolledToStepTwo = true;
        let vm = this;
        this.$nextTick(() => {
          const element = document.querySelector('#scrollToStepTwo');
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        })
      }
    }
  },

  computed: {
    brandName: function () {
      if (this.brandObject.id === 4) return 'EZE';
      if (this.brandObject.id === 7) return 'Anlin';
      if (this.brandObject.id === 6) return 'WWS';
    },
    frameOptions() {
      let options = [
        { id: 'aluminum', name: 'Aluminum' },
        { id: 'vinyl', name: 'Vinyl' },
        { id: 'not_sure', name: 'Not sure' },
        { id: 'other', name: 'Other' }
      ];
      let optionOrders = [];
      if (this.brand === 7) { // CU-2dknzhy
        optionOrders = [options[1], options[0], options[3], options[2]];
      } else {
        optionOrders = [options[0], options[1], options[2]];
      }
      return optionOrders;
    },
    url_prepend() {
      let val = this.frontend_url ? this.frontend_url : "";
      if (val && val.substring(val.length - 1) === "/") {
        val = val.substring(0, val.length - 1);
      }
      return val;
    },
    isModal() {
      return this.show_close;
    },
    brandRgbaTuple() {
      let defaultRgbaTuple = '58, 98, 149';
      if (this.brandObject.id === 4) {
        return defaultRgbaTuple;
      }
      if (this.brandObject.id === 7) {
        return '32, 25, 16';
      }
      return defaultRgbaTuple;
    },
    showStepTwo() {
      if (
        this.quote_request.serviceType !== '' &&
        this.quote_request.homeType !== '' &&
        this.quote_request.interestedProducts.length &&
        this.quote_request.framePreference !== '' &&
        this.quote_request.interested_installation !== '' &&
        this.quote_request.windowImportance !== '' &&
        this.quote_request.newBuyPlan !== '' &&
        this.quote_request.interested_financing !== ''
      ) {
        let isInterestedProductCaptured = false;
        if(this.quote_request.interestedProducts.includes('1') && this.quote_request.interestedProducts.includes('2')){
          if(this.quote_request.windowAmount !== '' && this.quote_request.doorAmount !== ''){
            isInterestedProductCaptured = true;
          }
        }else if(this.quote_request.interestedProducts.includes('1')){
          if(this.quote_request.windowAmount !== ''){
            isInterestedProductCaptured = true;
          }
          this.quote_request.doorAmount = '';
        }else if(this.quote_request.interestedProducts.includes('2')){
          if(this.quote_request.doorAmount !== ''){
            isInterestedProductCaptured = true;
          }
          this.quote_request.windowAmount = '';
        }
        if(isInterestedProductCaptured){
          this.initAddressAutoComplete();
          return true;
        }
      };
    },
    brandAuthorizedThirdPartiesUrl() {
      if (this.brandObject.authorized_third_parties_url) {
        return this.brandObject.authorized_third_parties_url;
      }
      return null;
    },
    shouldShowAddressConfirmationPopup() {
      return this.brandObject?.force_lead_address_validation === 1
        && this.shouldConfirmAddress === true
        && this.addressConfirmed === false;
    },
    brandFormTitle() {
      if (this.brand === 8) {
        return 'Talk to a Western Window Systems expert.';
      }
      return 'Get a free quote';
    }
  },
  methods: {
    homeTypeSelected() {
      if(this.shownOnModal === true){
        let  windowAmountElement = document.getElementById('window-count-container-on-modal');
        let  doorAmountElement = document.getElementById('door-count-container-on-modal');
        if(document.getElementsByName("interestedProducts")[2].checked === true){
          windowAmountElement.classList.remove('disable-content');
        }else{
          windowAmountElement.classList.add('disable-content');
        }
        if(document.getElementsByName("interestedProducts")[3].checked === true){
          doorAmountElement.classList.remove('disable-content');
        }else{
          doorAmountElement.classList.add('disable-content');
        }
      }else{
        let windowAmountElement = document.getElementById('window-count-container');
        let doorAmountElement = document.getElementById('door-count-container');
        if(document.getElementsByName("interestedProducts")[0].checked === true){
          windowAmountElement.classList.remove('disable-content');
        }else{
          windowAmountElement.classList.add('disable-content');
        }
        if(document.getElementsByName("interestedProducts")[1].checked === true){
          doorAmountElement.classList.remove('disable-content');
        }else{
          doorAmountElement.classList.add('disable-content');
        }

      }
    },
    referralInfo() {
      if (parent !== window) {
        return {
          full_url: document.referrer
        }
      }
      return {
        full_url: window.location.href
      };
    },
    sanitizeZipCode() {
      this.quote_request.zip = this.quote_request.zip.replace(/\D/g, "");
    },
    sanitizePhoneNumber() {
      var tmp = this.quote_request.phone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.quote_request.phone = !tmp[2]
        ? tmp[1]
        : "(" + tmp[1] + ") " + tmp[2] + (tmp[3] ? "-" + tmp[3] : "");
    },
    initGoogleApi(callback) {
      if (typeof google !== "undefined" && google.maps && google.maps.places) {
        callback.apply(this, arguments);
        return;
      }
      let el = document.createElement("script");
      el.src =
        "https://maps.googleapis.com/maps/api/js?key=" +
        process.env.MIX_GMAP_API_KEY +
        "&libraries=places&language=en";
      document.body.appendChild(el);
      let _t = this;
      return setTimeout(function () {
        callback.apply(this, arguments);
      }, 2000);
    },
    initAddressAutoComplete: function () {
      // if (gmapAutocompleteInitialized) return true;
      // gmapAutocompleteInitialized = true;
      var _t = this;
      this.initGoogleApi(function () {
        var input = _t.$refs.inputAddress;
        var autocomplete = new google.maps.places.Autocomplete(input);
        google.maps.event.addListener(
          autocomplete,
          "place_changed",
          function () {
            var place = autocomplete.getPlace();
            if (place && place.address_components) {
              _t.shouldConfirmAddress = false;
              _t.quote_request.confirmation_popup_gmap_suggestion_selected = 'no';
              var address_components = place.address_components;
              let outStreetNumber = "";
              let outStreetName = "";
              let outCity;
              let outZip = "";
              let outCounty = "";
              let outState = "";
              let outCountry = "";
              for (var i = 0; i < address_components.length; i++) {
                let tComponent = address_components[i];
                let componentType = tComponent.types;
                if (!componentType) return;
                if (componentType.indexOf("street_number") > -1) {
                  outStreetNumber = tComponent.long_name;
                } else if (componentType.indexOf("route") > -1) {
                  outStreetName = tComponent.long_name;
                } else if (componentType.indexOf("locality") > -1) {
                  outCity = tComponent.long_name;
                } else if (componentType.indexOf("postal_code") > -1) {
                  outZip = tComponent.long_name;
                } else if (
                  componentType.indexOf("administrative_area_level_1") > -1
                ) {
                  outState = tComponent.long_name;
                } else if (
                  componentType.indexOf("administrative_area_level_2") > -1
                ) {
                  outCounty = tComponent.long_name;
                } else if (componentType.indexOf("country") > -1) {
                  outCountry = tComponent.long_name;
                }
              }
              _t.quote_request.address1 = outStreetNumber + " " + outStreetName;
              _t.quote_request.city = outCity;
              // Auto filled on select
              _t.quote_request.zip = outZip;
              if (outCountry && _t.countries.indexOf(outCountry) > -1) {
                _t.quote_request.country = outCountry;
              }
              if (outState) {
                _t.quote_request.state = outState;
              }
              if (outCounty) {
                _t.quote_request.county = outCounty;
              }
            }
          }
        );
      });
    },
    postLead() {
      var $this = this;
      if (window.CLabsgbVar !== undefined) {
        $this.cluid = window.CLabsgbVar.generalProps.uid;
      }
      this.checkForm();
      console.log(this.errors);
      if (this.has_errors) {
        return;
      }

      if (this.shouldShowAddressConfirmationPopup) {
        this.quote_request.confirmation_popup_gmap_suggestion_selected = 'no';
        this.$modal.show('confirm-address-eze');
        return;
      }

      $this.isLoading = true;

        window.dataLayer.push({
            'event':'leadSubmission',
            'leadData':this.quote_request
        });
        console.log(window.dataLayer);
      axios
        .post(this.url_prepend + "/api/lead", $this.quote_request)
        .then((response) => {
          if (response.data.success) {
            $this.$emit("close");

            // Redirect and break;
            setTimeout(function () {
              if ($this.redirect_thankyou) {
                parent.location = $this.redirect_thankyou;
              }
            }, 400);
            if ($this.redirect_thankyou) {
              return;
            }

            // Code below will not execute unless falsy .redirect_thankyou

            setTimeout(() => {
              parent.postMessage({task: "scroll_top"}, "*");
            }, 50);
            $this.$scrollTo($this.$refs.pageTop);
            $this.isLoading = false;
            window.location = '/thank-you'; // CU-2d8ecpb
            return;
            Swal.fire({
              icon: "success",
              title: "Thank you for your interest in our products.",
              text: "An authorized dealer will contact you shortly.",
              showConfirmButton: false,
              timer: 10000,
              allowEnterKey: false,
              allowEscapeKey: false,
              allowOutsideClick: false,
              position: "top",
            });

            setTimeout(function () {
              $this.step = 1;
            }, 10000);
            $this.quote_request = {
              serviceType: 1,
              newBuyPlan: 1,
              homeType: 1,
              windowAmount: 1,
              doorAmount: 1,
              leadType: "H",
              windowImportance: [],
              interestedProducts: this.brandObject.id === 4 ? [] : [],
              oInterestedProducts: "",
              oWindowAmount: "",
              oWindowImportance: "",
              fullName: "",
              firstName: "",
              lastName: "",
              email: "",
              phone: "",
              address1: "",
              city: "",
              state: "",
              zip: "",
              county: "",
              patio_cover: "",
              do_you_have_measurements: "",
              additional: "",
              country: this.default_country,
              dealer_id: $this.dealer ? $this.dealer.id : "",
              bDescription: "1",
              bProject: "1",
              bProjectType: "1",
              bLocation: "",
              bWindowAmount: "1",
              bDoorAmount: "1",
              bAdditional: "",
              newsletter: null,
              contactMethod: null,
              brand: this.brand,
              source_id: this.source_id,
              interested_financing: this.interested_financing,
              interested_installation: "yes",
              redirect_thankyou: this.redirect_thankyou,
              referral_info: this.referralInfo()
            };
            $this.hasOInterestedProducts = 0;
            $this.hasOWindowAmount = 0;
            $this.hasOWindowImportance = 0;
            $this.address = "";
            $this.errors = $this.validations();
            $this.has_errors = false;
          }
        })
        .catch((error) => {
          $this.$scrollTo($this.$refs.pageTop);
          $this.isLoading = false;
          Swal.fire("Error Processing Request!", "Please try again", "warning");

          if (error.response.status == 401) {
            this.initApiToken();
          }
        });
    },
    formatResult(place) {
      var filter = {
        street_number: "short_name",
        route: "long_name",
        locality: "long_name",
        administrative_area_level_1: "short_name",
        administrative_area_level_2: "county",
        country: "long_name",
        postal_code: "short_name",
      };

      let returnData = {};
      for (let i = 0; i < place.address_components.length; i++) {
        let addressType = place.address_components[i].types[0];

        if (filter[addressType]) {
          let val = place.address_components[i][filter[addressType]];
          returnData[addressType] = val;
        }
      }

      returnData["latitude"] = place.geometry.location.lat();
      returnData["longitude"] = place.geometry.location.lng();
      return returnData;
    },
    getAddressData: function (place) {
      var state = "";
      var city = "";
      var country = "";
      var zip = "";
      var address1 = "";
      var addressData = null;

      if (place.address_components !== undefined) {
        addressData = this.formatResult(place);
      }

      for (var i = 0; i < place.address_components.length; i++) {
        if (place.address_components[i].types[0] == "locality") {
          city = place.address_components[i];
        }
        if (
          place.address_components[i].types[0] == "administrative_area_level_1"
        ) {
          state = place.address_components[i];
        }
        if (place.address_components[i].types[0] == "country") {
          country = place.address_components[i];
        }
      }

      this.quote_request.city =
        typeof city.long_name !== "undefined" ? city.long_name : "";
      this.quote_request.state =
        typeof state.long_name !== "undefined" ? state.long_name : "";
      this.quote_request.country =
        typeof country.long_name !== "undefined" ? country.long_name : "";
      this.quote_request.zip =
        typeof addressData.postal_code !== "undefined"
          ? addressData.postal_code
          : "";
      this.quote_request.address1 =
        typeof addressData.route !== "undefined" ? addressData.route : "";
    },
    initData() {
      return {
        isLoading: false,
        fullPage: false,
        cluid: "",
        quote_request: {
          serviceType: "",
          homeType: "",
          newBuyPlan: "",
          windowAmount: "",
          doorAmount: "",
          brand: this.brandObject.id,
          leadType: "H",
          windowImportance: [],
          interestedProducts: this.brandObject.id === 4 ? [] : [],
          framePreference: {
            aluminum: 0,
            vinyl: 0,
            not_sure: 0,
          },
          oInterestedProducts: "",
          oWindowAmount: "",
          oWindowImportance: "",
          fullName: "",
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          address1: "",
          city: "",
          state: "",
          zip: "",
          county: "",
          // New Eze form questions
          patio_cover: "",
          do_you_have_measurements: "",
          additional: "",
          // End eze questions
          country: this.default_country,
          dealer_id: this.dealer ? this.dealer.id : "",
          bDescription: "",
          bProject: "",
          bProjectType: "",
          bLocation: "",
          bWindowAmount: "",
          bDoorAmount: "",
          bAdditional: "",
          newsletter: null,
          contactMethod: null,
          source_id: this.source_id,
          interested_financing: "",
          interested_installation: "",
          redirect_thankyou: this.redirect_thankyou,
          client_ip: this.client_ip,
          user_agent: this.user_agent,
          referral_info: this.referralInfo(),
          confirmation_popup_gmap_suggestion_selected: 'no',
        },
        address: "",
        errors: this.validations(),
        has_errors: false,
        empty_value: false,
        b_description: this.business_types,
        b_project: this.project_conditions,
        b_project_type: this.project_types,
        b_window_amount: "",
        b_door_amount: "",
        b_addional: "",
        hasOInterestedProducts: 0,
        hasOWindowAmount: 0,
        hasOWindowImportance: 0,
        questions: {
          whatBestDescribesYou: [
            { value: "H", answer: "Homeowner" },
            { value: "B", answer: "Industry Professional" },
          ],
          productsOfInterest: [
            { value: "4", answer: "Vertical Sliders (Open Up & Down)" },
            { value: "5", answer: "Horizontal Sliders (Open Side to Side)" },
            { value: "6", answer: "Fixed Panels (Do Not Open)" },
            { value: "7", answer: "Screen Porch Door" },
            { value: "8", answer: "Garage Enclosure" },
          ],
          projectInstallation: [
            { value: "yes", answer: "Yes, I need installation services" },
            { value: "no", answer: "No, I have installer" },
            { value: "diy", answer: "No, I will install myself" },
            { value: "helpme", answer: "Not sure, help me decide" },
          ],
          patioCover: [
            { value: "1", answer: "By the same roof as the house/building" },
            { value: "2", answer: "By an extension" },
            { value: "3", answer: "Patio is not covered" },
          ],
          doYouHaveMeasurements: [
            { value: "1", answer: "Yes" },
            { value: "2", answer: "No" },
          ],
          specialFinancing: [
            { value: "1", answer: "Yes" },
            { value: "0", answer: "No" },
          ],
        },
        step: 1,
        scrolledToStepTwo: false,
        shouldConfirmAddress: false,
        addressConfirmed: false,
      };
    },
    validations() {
      let validations = {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        address1: "",
        city: "",
        zip: "",
      }

      if (this.brandObject.id === 4) {
        validations.leadType = "";
        validations.interestedProducts = "";
        validations.interested_installation = "";
        validations.serviceType = "";
        validations.patio_cover = "";
        validations.do_you_have_measurements = "";
        validations.newBuyPlan = "";
        validations.interested_financing = "";
        validations.additional = "";
      }

      return validations;
    },
    selectSpecialFinancing(value) {
      this.quote_request.interested_financing = value;
    },
    checkForm() {
      this.has_errors = false;
      this.errors = this.validations();


      if (this.quote_request.leadType === "H") {
          if (this.quote_request.firstName == "") {
            this.errors.firstName = "First Name is required";
            this.has_errors = true;
          }

          if (this.quote_request.lastName == "") {
            this.errors.lastName = "Last Name is required";
            this.has_errors = true;
          }

          if (!this.quote_request.email) {
            this.errors.email = "Email is required";
            this.has_errors = true;
          } else if (!this.validEmail(this.quote_request.email)) {
            this.errors.email = "Valid email required";
            this.has_errors = true;
          }

          if (!this.quote_request.phone) {
            this.errors.phone = "Phone is required";
            this.has_errors = true;
          } else if (this.quote_request.phone.replace(/\D/g, "").length < 10) {
            this.errors.phone = "Enter a valid phone number";
            this.has_errors = true;
          }

          if (this.quote_request.address1 == "") {
            this.errors.address1 = "Address is required";
            this.has_errors = true;
          }

          if (this.quote_request.city == "") {
            this.errors.city = "City is required";
            this.has_errors = true;
          }

          console.log(this.quote_request.zip.length);

          if (this.quote_request.zip == "" || this.quote_request.zip.length < 5) {
            this.errors.zip = "Postal Code is required";
            this.has_errors = true;
          }

          // Don't validate State if it's outside the US
          if (this.quote_request.country == this.default_country) {
            if (this.quote_request.state == "") {
              this.errors.state = "State is required";
              this.has_errors = true;
            }
          }
        } else {
          this.has_errors = false;
          this.validations();
        }
    },
    validEmail(email) {
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    initApiToken() {
      axios
        .post(this.url_prepend + "/api/token/request", {
          dealer_id: this.dealer.id,
          secret: this.dealer.api_secret
            ? this.dealer.api_secret
            : "dealer-secret",
        })
        .then(
          (response) => {
            if (response.data.success) {
              console.log(response.data.message);
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },

    handleAddressConfirmed(event) {
      if (event.gmapSuggestionSelected === true) {
        this.quote_request.address1 = event.gmapSuggestedAddress.address1;
        this.quote_request.city = event.gmapSuggestedAddress.city;
        this.quote_request.state = event.gmapSuggestedAddress.state;
        this.quote_request.zip = event.gmapSuggestedAddress.zip;
        this.quote_request.country = event.gmapSuggestedAddress.country;
        this.quote_request.county = event.gmapSuggestedAddress.county;
        this.quote_request.latitude = event.gmapSuggestedAddress.latitude;
        this.quote_request.longitude = event.gmapSuggestedAddress.longitude;
        this.quote_request.confirmation_popup_gmap_suggestion_selected = 'yes';
      } else {
        this.quote_request.confirmation_popup_gmap_suggestion_selected = 'no';
      }

      this.addressConfirmed = true;
      this.$modal.hide('confirm-address-eze');
      this.postLead();
    }
  },
};
</script>

<style lang="scss">
#select-state:invalid {
  color: #A0ADC0;
}

#select-state {
  background-color: white;
}

input.eze-form-radio[type="radio"],
input.eze-form-small-checkbox[type="checkbox"] {
  position: unset !important;
  padding: unset !important;
}

input.eze-form-radio[type="radio"] {
  margin-bottom: 0;
}

.wtb-wrapper {

  input:not([type="file"]):not([type="submit"]):not([type="radio"]),
  select,
  .has-selectlabel label {
    margin: unset !important;
  }

  input.additionalDetails[type="text"] {
    max-width: initial;
  }
}

.pac-container {
  z-index: 10000 !important;
}


[data-forceStyle="ldisclaimer_EZE"] {
  margin-top: 4.5rem !important;
  padding-top: 0 !important;

  @media only screen and (max-width: 991px) {
    margin-top: 4.75rem !important;
  }
}

.l-disclaimer {

  a,
  a:visited {
    text-decoration: underline;
    color: #5ac1a7 !important;
  }
}

.modal-override {
  @apply rounded-br-15;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 80% !important;
  max-width: 900px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  left: unset !important;
}
</style>
