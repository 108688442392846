<template>
    <!--HEADER-->
    <div class="container md:mx-auto p-4 ez-header-comp">
        <a href="/">
            <img v-if="brand.id === 4" :src="url_prepend + '/assets/images/eze/logo.png'" class="md:m-auto md:w-auto w-32" alt="">
            <img v-if="brand.id === 7" :src="url_prepend + '/images/logos/anlin.png'" class="md:m-auto w-56" alt="">
            <img v-if="brand.id === 9" :src="url_prepend + '/images/logos/martin-door-tagline.png'" class="md:m-auto w-56 md:w-72" alt="">
        </a>
    </div>
    <!--HEADER-->
</template>

<script>
export default {
    props: {
        brand: Object,
        frontend_url: ''
    },
    mounted(){
        this.loadGTM();
    },
    methods:{
        loadGTM() {
            // Check if GTM is not already loaded
            var gtmContainer = 'KPK52H';
            if (this.brand.id === 9) {
                gtmContainer = 'M6M63LV';
            }
            if (!window.dataLayer || !Array.isArray(window.dataLayer)) {
                window.dataLayer = [];
                window.dataLayer.push({
                    'gtm.start': new Date().getTime(),
                    event: 'gtm.js'
                });
                const gtmScript = document.createElement('script');
                gtmScript.async = true;
                gtmScript.src = 'https://www.googletagmanager.com/gtm.js?id=GTM'+gtmContainer;
                document.head.appendChild(gtmScript);
            }
        }
    },
    computed: {
        url_prepend() {
            return this.frontend_url ? this.frontend_url : "";
        },
    }
}
</script>
