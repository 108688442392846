<template>
    <div :class="full_wrapper_classes">
        <div class="form_section text-left" data-form="unique">
            <loading
                :active.sync="isLoading"
                :can-cancel="false"
                :is-full-page="false"
            ></loading>
            <button id="closeModal" @click.prevent="$modal.hide('confirm-address')">
                x
            </button>
            <div>
                <div class="step_form two new_step-form">
                    <div class="step_form_top step-title">
                        <h2>
                            We found a suggested address.
                        </h2>
                    </div>
                    <div :class="[{'opacity-0': isLoading}, 'step_form_main']">
                        <div class="group_windows_list">
                            <div v-show="googleProvidedAddressFormatted" :class="[{'checked': address_source === 1 }, 'group_windows radio']"
                                 @click="select(1)">
                                <label>
                                    <span>Use suggested address: {{ googleProvidedAddressFormatted }}</span>
                                </label>
                            </div>
                            <div :class="[{'checked': address_source === 2 }, 'group_windows radio']"
                                 @click="select(2)">
                                <label>
                                    <span>Use address I entered:  {{ userInputAddressFormatted }}</span>
                                </label>
                            </div>
                            <div class="form-error mt-2" style="display: none;">
                                Please select an option
                            </div>
                        </div>
                        <div class="button_cont" style="">
                            <button @click.prevent="submitSelection" class="custom_btn next_btn" type="button"><i class="next_arrow">⟶</i>
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import Loading from "vue-loading-overlay";

export default {
    name: "ConfirmAddressForm",

    components: {
        Loading,
    },

    props: {
        full_wrapper_classes: {
            type: String,
            default: 'wp-wrapper wtb-wrapper',
        },
        quote_request: {
            type: Object,
            default: null,
        },
        url_prepend: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            isLoading: true,

            options: [
                {id: 1, name: 'google-maps-address'},
                {id: 2, name: 'user-input-address'},
            ],

            address_source: 1,

            addressPickedViaGmapDropdown: false,

            googleProvidedAddress: null,
            googleProvidedAddressFormatted: null,

            userInputAddressFormatted: null,

            addressConfirmed: false,
            addressNotFound: false,
            pickedGmapAddressViaPopup: false,
            gmapSuggestedAddress: {
                address1: null,
                city: null,
                state: null,
                zip: null,
                country: null,
                county: null,
                latitude: null,
                longitude: null,
            },
        }
    },

    mounted() {
        let country = this.quote_request.country === 'United States' ? 'USA' : this.quote_request.country;
        this.userInputAddressFormatted = `${this.quote_request.address1} ${this.quote_request.city} ${this.quote_request.state} ${this.quote_request.zip} ${country}`;
        this.validateAddressViaGoogle();
    },

    methods: {
        async validateAddressViaGoogle() {
            this.addressPickedViaGmapDropdown = false;
            this.addressConfirmed = false;

            await axios
                .post(this.url_prepend + '/api/gmaps-proxy', {
                    url: 'https://maps.googleapis.com/maps/api/geocode/json?address=' + encodeURI(this.userInputAddressFormatted)
                })
                .then(r => {
                    if (r && r.data && r.data.results && r.data.results[0]) {
                        let result = r.data.results[0];
                        this.googleProvidedAddress = result;
                        this.googleProvidedAddressFormatted = result.formatted_address;
                        this.addressNotFound = false;
                    } else {
                        this.googleProvidedAddress = null;
                        this.googleProvidedAddressFormatted = null;
                        this.addressNotFound = true;
                    }
                })
                .catch(e => {
                    this.googleProvidedAddress = null;
                    this.googleProvidedAddressFormatted = null;
                    this.addressNotFound = true;
                    throw e
                })
                .finally(() => {
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 500)
                })
        },
        select(id) {
            this.address_source = id;
        },
        submitSelection() {
            let gmapSuggestionSelected = this.address_source === 1;
            if (this.googleProvidedAddress && gmapSuggestionSelected) {
                let streetName = this.googleProvidedAddress.address_components.find(e => e.types.indexOf('route') > -1);
                if (streetName) streetName = streetName.long_name;
                let streetNumber = this.googleProvidedAddress.address_components.find(e => e.types.indexOf('street_number') > -1);
                if (streetNumber) streetNumber = streetNumber.long_name;
                let address1 = null;
                if (streetName && streetNumber) {
                    this.gmapSuggestedAddress.address1 = streetNumber + ' ' + streetName;
                }
                let city = this.googleProvidedAddress.address_components.find(e => e.types.indexOf('locality') > -1);
                if (city) this.gmapSuggestedAddress.city = city.long_name;
                let state = this.googleProvidedAddress.address_components.find(e => e.types.indexOf('administrative_area_level_1') > -1);
                if (state) this.gmapSuggestedAddress.state = state.long_name;
                let zip = this.googleProvidedAddress.address_components.find(e => e.types.indexOf('postal_code') > -1);
                if (zip) this.gmapSuggestedAddress.zip = zip.long_name;
                let country = this.googleProvidedAddress.address_components.find(e => e.types.indexOf('country') > -1);
                if (country) this.gmapSuggestedAddress.country = country.long_name;
                let county = this.googleProvidedAddress.address_components.find(e => e.types.indexOf('administrative_area_level_2') > -1);
                if (county) this.gmapSuggestedAddress.county = county.long_name;

                this.gmapSuggestedAddress.latitude = this.googleProvidedAddress.geometry?.location?.lat;
                this.gmapSuggestedAddress.longitude = this.googleProvidedAddress.geometry?.location?.lng;
            }


            this.googleProvidedAddress = null;
            this.addressNotFound = false;
            this.$emit('address-confirmed', {
                gmapSuggestionSelected: gmapSuggestionSelected,
                gmapSuggestedAddress: this.gmapSuggestedAddress,
            });
        },
    },
}
</script>

<style scoped>
#closeModal {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #ffffff;
    background-color: transparent;
    border: 0;
}
</style>
