import { render, staticRenderFns } from "./ConfirmAddressForm.vue?vue&type=template&id=635d71fe&scoped=true&"
import script from "./ConfirmAddressForm.vue?vue&type=script&lang=js&"
export * from "./ConfirmAddressForm.vue?vue&type=script&lang=js&"
import style0 from "./ConfirmAddressForm.vue?vue&type=style&index=0&id=635d71fe&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "635d71fe",
  null
  
)

export default component.exports